import '../App.css';
import Navigation from '../componets/Navigation'
import Footer from '../componets/Footer';
import { globalVar } from '../globalVar';
function Ogloszenia() {
  return (<>
        <Navigation />
        <div className='article'>
            <div className='title'>Ogłoszenia {globalVar.map((map,index) => {return map.niedziela})} </div>
            <p>Kalendarz Liturgiczny</p>
            <ul>
                <li>piątek - wspomnienie św.Łucji, dziewicy męczennicy</li>
                <li>sobota - wspomnienie św.Jana od Krzyża, kapłana, doktora Kościoła</li>
            </ul>
            <p>
                W Adwencie – od poniedziałku do soboty – Roraty o godz.7.00. Zachęcam dzieci,
                młodzież i dorosłych do uczestnictwa. Przed Roratami o godz. 6.30 Godzinki ku czci
                NMP. Spowiedź przedświąteczna w piątek 20 grudnia, wtedy również odwiedziny chorych
                w domach z posługą sakramentalną (proszę o zapisywanie ich w zakrystii).
            </p>
            <p>
                W poniedziałek na Mszy św. o godz.18.00, dzieci z klas III-ch, które przygotowują
                się do przyjęcia sakramentu Eucharystii, bedą miały poświęcone i nałożone
                medaliki.
            </p>
            <p>
                W środę o godz.18.00 Msza św. i Nowenna do MBNP, przed Mszą św. różaniec w
                intencji kandydatów do Bierzmowania, prowadzony przez II Różę.
            </p>
            <p>
                Kandydaci do Bierzmowania w poniedziałek, środę i piątek na Mszy św. o
                godz.18.00 przeżywać będą Triduum przed tą ważną uroczystością, proszę również
                rodziców, rodziny do uczestnictwa w tej modlitwie. Po Mszy św. w te dni próby przed
                Bierzmowaniem.
            </p>
            <div className='title'>WIZYTACJA KANONICZNA</div>
            <div className='title'>PARAFII NAWIEDZENIA NMP W PIOTROWICACH</div>
            <div className='title'>15 GRUDZIEŃ 2024</div>
            <ol>
                <li>Powitanie J.E.Bp-a Roberta przez przedstawicieli Parafii i Proboszcza</li>
                <li>Msza św. o godz. 7.00</li>
                <li>Spotkanie z Różami Różańcowymi i Bractwem NSPJ w kościele</li>
                <li>Msza św. o godz.9.00 w kaplicy na Łowiczkach</li>
                <li>Msza św. dla dzieci i młodzieży z udzieleniem Sakramentu Bierzmowaniakandydatom z klas VIII-ych o godz.11.00</li>
                <li>Spotkanie z Radą Duszpasterską na plebanii</li>
                <li>Modlitwa na cmentarzu w intencji zmarłych ok. godz.14.00</li>
            </ol>
            <p>
                PP. Organista w najbliższym tygodniu rozniesie poświęcone opłatki na stół wigilijny,
                złożone przy tej okazji ofiary są częścią wynagrodzenia za całoroczną posługę.
            </p>
            <ul>
                <li>11.12 środa godz.8.00 – ul.Lipowa (od rodz. Grzesło)</li>
                <li>14.12 sobota godz.8.00 – ul.Kasztanowa (od parku), Modrzewiowa</li>
            </ul>
            <p>
                Caritas Archidiecezji rozprowadza świece Wigilijnego Dziela Pomocy Dzieciom,
                można je nabyć ofiarując 12zł, które przeznaczone są na dzieła charytatywne prowadzone
                przez Caritas.
            </p>
            <p>
                Zakończone zostały tegoroczne prace przy modernizacji i remoncie dachu naszej
                świątynii, w minionym tygodniu zabezpieczono dach przed deszczem i opadami śniegu,
                przykrywając go odpowiednią matą. Przywieziono I część dachówki na poszycie dachy, II
                część dostarczona będzie w dniu jutrzejszym. Dziękuję Sebastianowi Makuchowi za
                pomoc przy rozładunku.
            </p>
            <p>
                Dziękuję za sprzątanie świątynii na dzisiejszą niedzielę i ofiarę 100zł, na przyszłą
                niedzielę troskę o świątynię powierzamy rodzicom kandydatów, którzy przystapią do
                sakramentu Bierzmowania.
            </p>
            <p>
                Dziękuję za sprzątanie kaplicy na dzisiejszą niedzielę i ofiarę 150zł, na przyszłą
                niedzielę troskę o kaplicę powierzamy rodzinom:
            </p>
            <ul>
                <li>Krzysztofa Kozaczka</li>
                <li>Andrzeja i Renaty Warmuz</li>
                <li>Marka i Moniki Kozaczka</li>
                <li>Józefa i Marzeny Seremet</li>
            </ul>
            <p>
                Dziękuję również za prace porządkowe przy kaplicy, które podjęli i wykonali:
                Kazimierz Stachura, Piotr Pękala, Ryszard Semik, Andrzej Wojtaszek, Adam Czopek,
                Wojciech Kajdas, Marek Cichoń
            </p>
            <p>
                Dyrekcja wraz z Radą Rodziców Samorządowego Przedszkola „Leśne Zacisze” w
                Piotrowicach serdecznie zapraszają na kiermasz świąteczny, który odbędzie się 13 grudnia
                o godz.16.00 w Remizie OSP w Piotrowicach. Przedszkolaki wystapią w świątecznym
                przedstawieniu, będzie można zakupić piekne ozdoby, smaczne ciasta oraz poczuć
                atmosferę przygotowania do zbliżających się świąt.
            </p>
            <p>
                Szkoła Podstawowa w Piotrowicach serdecznie zaprasza na jarmark świąteczny,
                który odbędzie się w szkole 19 grudnia o godz.17.00 (szczegóły w gablocie).
            </p>
            <p>
                27 grudnia Mszą św. o godz.18.00 rozpocznie się II Rodzinne Kolędowanie – za
                organami zasiądzie Pan Piotr Kmiecik organista krakowski, śpiew poprowadzi Chór
                Szkolny „Perfectum” pod batutą Pani Iwony Bernaś, serdecznie zapraszam.
            </p>
        </div>
        <Footer />
  </>
  );
}

export default Ogloszenia;
