import Navigation from '../componets/Navigation'
import Footer from '../componets/Footer'

function StandardyOchronyMałoletnich() {
    const downloadPDF = () => {
        const fileUrl = "/pages/STANDARDY.pdf"
        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = "STANDARDY.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const downloadODT = () => {
        const fileUrl = "STANDARDY.odt"
        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = "STANDARDY.odt";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const downloadDOCX = () => {
        const fileUrl = "STANDARDY.docx"
        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = "STANDARDY.docx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    return(
        <>
            <Navigation />
            <div className='article'>
                <div className='title'>Standardy ochrony małoletnich</div>
                    <div className='subtitle'>Wersje do pobrania</div>
                    <button onClick={downloadPDF}>Wersja PDF</button>
                    <button onClick={downloadDOCX}>Wersja DOCX</button>
                    <button onClick={downloadODT}>Wersja ODT</button>
                    <p><em>&nbsp;</em></p>
                    <p>&nbsp;</p>
                    <p><strong>STANDARDY OCHRONY MAŁOLETNICH W PARAFII</strong></p>
                    <p><strong>p.w. NAWIEDZENIA NMP w PIOTROWICACH</strong></p>
                    <p>&nbsp;</p>
                    <p><strong>I.Wstęp</strong></p>
                    <p>&nbsp;</p>
                    <p>&nbsp;Wprowadzając niniejsze Standardy, Parafia p.w. Nawiedzenia NMP w Piotrowicach wykonuje obowiązek, o kt&oacute;rym mowa w art. 22b i 22c Ustawy z dnia 13 maja 2016 r. o przeciwdziałaniu zagrożeniom przestępczością na tle seksualnym i ochronie małoletnich (t.j. Dz. U. z2024 r., poz. 560). Parafia p.w. Nawiedzenia NMP w Piotrowicach jest organizatorem działalności na rzecz Małoletnich, prowadzonej w sferach wskazanych w ww. Ustawie.</p>
                    <p>Wprowadzane Standardy zostały zredagowane w oparciu o Standardy Ochrony Małoletnich w Archidiecezji Krakowskiej, wprowadzone dnia 2 sierpnia 2024 roku.</p>
                    <p>&nbsp;</p>
                    <p><strong>II. Podstawy prawne</strong></p>
                    <p>Niniejsze Standardy stworzono w oparciu o:</p>
                    <p>1)Ustawę z dnia 13 maja 2016 r. o przeciwdziałaniu zagrożeniom przestępczością na tle seksualnym i ochronie małoletnich (t.j. Dz. U. z2024 r., poz. 560) &ndash; dalej: Ustawa;</p>
                    <p>2)Kodeks prawa kanonicznego promulgowany przez papieża Jana Pawła II w dniu 25 stycznia 1983 r., stan prawny na dzień 8 sierpnia 2023 r., zaktualizowany przekład na język polski &ndash; dalej: Kodeks prawa kanonicznego;</p>
                    <p>3)Dekret Arcybiskupa Marka Jędraszewskiego Metropolity Krakowskiego Nr 4533/2021 z dnia 15 grudnia 2021 roku ustanawiający &bdquo;Regułę poszanowania godności i d&oacute;br osobistych bliźniego, szczeg&oacute;lnie dzieci i młodzieży oraz os&oacute;b bezradnych w Archidiecezji Krakowskiej&rdquo; &ndash; dalej: Dekret lub Reguła;</p>
                    <p>4)Ustawę z dnia 9 czerwca 2022 r. o wspieraniu i resocjalizacji nieletnich (Dz. U. Z 2022 r., poz. 1700); - dalej: ustawa o wspieraniu i resocjalizacji nieletnich;</p>
                    <p>5)Ustawę z dnia 6 czerwca 1997 r. Kodeks karny (t.j. Dz. U. Z 2024 r., poz. 17 ze zm.) &ndash; dalej: k.k.;</p>
                    <p>6)Ustawę z dnia 17 listopada 1964 r. Kodeks postępowania cywilnego (t.j. Dz. U. Z 2023 r., poz. 1550 ze zm.) &ndash; dalej: k.p.c.;</p>
                    <p>7)Ustawę z dnia 6 czerwca 1997 r. Kodeks postępowania karnego (t.j. Dz. U. Z 2024 r., poz. 37 ze zm.) &ndash; dalej: k.p.k.;</p>
                    <p>8)Ustawę z dnia 23 kwietnia 1964 r. Kodeks cywilny (t.j. Dz. U. Z 2023 r. poz. 1610 ze zm.) &ndash; dalej k.c.</p>
                    <ol>
                        <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Definicje</li>
                    </ol>
                    <p>Ilekroć w niniejszym dokumencie jest mowa o:</p>
                    <p>1)Standardach &ndash; należy pod tym pojęciem rozumieć standardy ochrony małoletnich w rozumieniu art. 22b i art. 22c Ustawy;</p>
                    <p>2)Działalności na rzecz Małoletnich &ndash; należy pod tym zwrotem rozumieć działalność na rzecz Małoletnich związaną z wychowaniem, edukacją, wypoczynkiem, leczeniem, świadczeniem porad psychologicznych, rozwojem duchowym, uprawianiem sportu lub realizacją innych zainteresowań przez Małoletnich, lub z opieką nad nimi w rozumieniu Ustawy;</p>
                    <p>3)Parafii lub Organizatorze &ndash; należy pod tymi pojęciami rozumieć Parafię p.w. Nawiedzenia NMP w Piotrowicach jako podmiot prowadzący działalność na rzecz Małoletnich w rozumieniu Ustawy i wprowadzający niniejsze Standardy;</p>
                    <p>4)Personelu Parafii &ndash; należy pod tym pojęciem rozumieć wszystkie osoby, przy pomocy kt&oacute;rych Parafia wykonuje działalność na rzecz Małoletnich, niezależnie od tego, na podstawie jakiego tytułu prawnego jest ona wykonywana;</p>
                    <p>5)Małoletnich &ndash; należy pod tym pojęciem rozumieć osoby, kt&oacute;re nie ukończyły 18 roku życia.</p>
                    <p>&nbsp;</p>
                    <p><strong>IV.Zasady zapewniające bezpieczne relacje między Małoletnim, a Personelem Parafii</strong></p>
                    <p>1.Bezpieczne relacje między Małoletnim a Personelem Parafii oparte są na następujących zasadach og&oacute;lnych:</p>
                    <p>1)Podstawą relacji między Małoletnim a Personelem Parafii, zar&oacute;wno osobistej, wsp&oacute;lnotowej, jak i podejmowanej za pomocą środk&oacute;w nawiązywania kontaktu, jest troska o prawdziwe dobro duchowe, psychiczne i fizyczne, a także respektowanie niezbywalnego prawa do poszanowania godności i potrzeba zagwarantowania bezpieczeństwa;</p>
                    <p>2)Personel Parafii jest obdarzony zaufaniem i wsparciem przełożonych. Ochrona Małoletnich nie może polegać na prewencyjnej, motywowanej bojaźnią, rezygnacji ze znanych lub nowych form duszpasterstwa i edukacji lub zaniechania podejmowania relacji z Małoletnimi. Organizator zachęca Personel Parafii do korzystania z dostępnych form ubezpieczenia od odpowiedzialności cywilnej;</p>
                    <p>3)Personel Parafii może być zobowiązany do jednoczesnego podporządkowania się niniejszym Standardom oraz standardom ochrony Małoletnich obowiązującym w podmiocie, w kt&oacute;rym, lub wsp&oacute;lnie z kt&oacute;rym prowadzi działalność. W sytuacji kolizji treści standard&oacute;w, Personel Parafii zobowiązany jest nadać pierwszeństwo stosowania Standardom obowiązującym w Parafii.</p>
                    <p>4)Wobec Personelu Parafii, przed dopuszczeniem do działalności na rzecz Małoletnich, Parafia wykonuje obowiązki, o kt&oacute;rych mowa wart. 21 Ustawy, w szczeg&oacute;lności weryfikuje kandydat&oacute;w do prowadzenia działalności na rzecz Małoletnich w Rejestrze Sprawc&oacute;w Przestępstw na tle Seksualnym oraz gromadzi dokumentację przedstawianą przez ww. kandydat&oacute;w.</p>
                    <p>5)Parafia, zgodnie z przepisami obowiązującego prawa kanonicznego i państwowego oraz wymogami kultury właściwej tradycji katolickiej, zabrania Personelowi Parafii stosowania wszelkich form przemocy i naruszania godności oraz d&oacute;br osobistych Małoletnich, a także podejmowania wszystkiego, co może prowadzić do takiego naruszenia, a nawet do wywołania podejrzenia lub wrażenia tego typu.</p>
                    <p>6)Dobro Małoletniego w wymiarze duchowym jest chronione przez Parafię zakazem kierowania życiem duchowym drugiego człowieka niezgodnie z nauczaniem i tradycją Kościoła Katolickiego.</p>
                    <p>7)Personel Parafii prowadząc działalność na rzecz Małoletnich obowiązany jest informować Małoletnich i ich rodzic&oacute;w lub opiekun&oacute;w prawnych o tym, kto jest Organizatorem danej działalności. Informacja powinna zawierać jednoznaczną identyfikację Organizatora i wskazanie, że jest nim Parafia. W razie prowadzenia działalności wsp&oacute;łorganizowanej z innymi podmiotami, Personel Parafii obowiązany jest przy nawiązywaniu wsp&oacute;łpracy sprawdzać, czy dany podmiot posiada własne standardy ochrony Małoletnich w rozumieniu Ustawy i czy informacja o wsp&oacute;łorganizowaniu danej działalności zawarta jest w materiałach informacyjnych przeznaczonych dla Małoletnich oraz ich rodzic&oacute;w lub opiekun&oacute;w prawnych.</p>
                    <p>8)Personel Parafii jest zobowiązany ze szczeg&oacute;lną uwagą, poszanowaniem i sprawiedliwością traktować własność materialną Małoletnich oraz wsp&oacute;lną, nie dopuszczając do jej niszczenia, kradzieży, wyłudzania lub przeznaczania na inne niż założone cele.</p>
                    <p>9)Personel Parafii zarządzający dobrami kościelnymi ma obowiązek utrzymywania ich w stanie, kt&oacute;ry zapewnia pełne bezpieczeństwo korzystających z nich Małoletnim.</p>
                    <p>&nbsp;</p>
                    <p>2.Zasady komunikacji z Małoletnimi, ich rodzicami lub opiekunami Małoletnich określa się następująco:</p>
                    <p>1) O ile umożliwia to sytuacja, Personel Parafii powinien nadawać pierwszeństwo relacjom osobowym w stosunku do relacji podejmowanych za pomocą innych środk&oacute;w komunikacji, np. elektronicznej.</p>
                    <p>2) W prowadzeniu korespondencji z Małoletnimi w jakiejkolwiek formie (rozumianej jako nawiązywanie kontaktu innego niż osobisty) zalecana jest ostrożność i, o ile to możliwe, korzystanie z kont i numer&oacute;w służbowych.</p>
                    <p>3.Kontakt fizyczny (cielesny) pomiędzy Personelem Parafii, a Małoletnim, dozwolony jest wyłącznie wtedy, gdy:</p>
                    <p>1)wymaga tego ochrona zdrowia lub życia Małoletniego,</p>
                    <p>2)jest związany ze zgodnym z liturgią Kościoła sprawowaniem świętych obrzęd&oacute;w oraz przyjętymi społecznie i przez prawo Kościoła godziwymi moralnie obyczajami lub powszechnie akceptowalnymi społecznie zasadami.</p>
                    <p>&nbsp;</p>
                    <p><strong>V.Zachowania Personelu Parafii niedozwolone wobec Małoletnich</strong></p>
                    <p>1. Personelowi Parafii zakazuje się kierować życiem Małoletniego w wymiarze duchowym niezgodnie z nauczaniem i tradycją Kościoła Katolickiego.</p>
                    <p>2. Personelowi Parafii zabrania się wobec Małoletnich stosowania przemocy psychicznej i emocjonalnej w postaci m.in.:</p>
                    <p>1) psychomanipulacji;</p>
                    <p>2) naruszania dobrego imienia;</p>
                    <p>3) pomawiania;</p>
                    <p>4) składania fałszywych oskarżeń;</p>
                    <p>5) <em>stalkingu&nbsp;</em>(uporczywego nękania innej osoby w rozumieniu art. 190a &sect; 1 k.k.);</p>
                    <p>6) gorszenia złym przykładem;</p>
                    <p>7) uzależniania emocjonalnego;</p>
                    <p>8) szantażowania;</p>
                    <p>9) grożenia;</p>
                    <p>10) powoływania się na wpływy, z zastrzeżeniem, że nie dotyczy to odwoływania do się do autorytetu dydaktycznego, wychowawczego lub moralnego innych os&oacute;b wchodzących w skład Personelu Archidiecezji Krakowskiej;</p>
                    <p>11) wymuszania decyzji;</p>
                    <p>12) intryg;</p>
                    <p>13) uwodzenia;</p>
                    <p>14) izolowania od innych;</p>
                    <p>15) złego traktowania ze względu na narodowość, rasę, płeć, wygląd, wykształcenie, poglądy polityczne, niepełnosprawność, szczeg&oacute;lne potrzeby edukacyjne lub przymioty osobowości;</p>
                    <p>16) tworzenia środowisk o charakterze sekty;</p>
                    <p>17) faworyzowania i bezzasadnego obdarowywania upominkami lub środkami materialnymi oraz przyjmowania ich;</p>
                    <p>18) nieuzasadnionych żądań finansowych;</p>
                    <p>19) namawiania do łamania prawa państwowego lub kanonicznego;</p>
                    <p>20) nieproporcjonalnego do winy oraz nieprzewidzianego w statutach karania i naznaczania poza sakramentalnych pokut;</p>
                    <p>21) poniżania;</p>
                    <p>22) wyśmiewania;</p>
                    <p>23) nieuzasadnionej krytyki;</p>
                    <p>24) upokarzania;</p>
                    <p>25) udzielania pomocy nieadekwatnej do potrzeb bliźniego;</p>
                    <p>26) stawiania wymagań ponad możliwości oraz kompetencje;</p>
                    <p>27) braku lub złej organizacji wsp&oacute;lnej pracy;</p>
                    <p>28) niewypełniania ważnie zawartych um&oacute;w;</p>
                    <p>29) łamania przewidzianej w prawie tajemnicy duszpasterskiej;</p>
                    <p>30) niezgodnego z wewnętrznymi statutami wykluczania z życia wsp&oacute;lnot;</p>
                    <p>31) nieuzasadnionego zobowiązywania do zachowania tajemnicy;</p>
                    <p>32) umniejszania autorytetu przełożonych i opiekun&oacute;w;</p>
                    <p>33) nierespektowania czasu wolnego i odpoczynku;</p>
                    <p>34) podejmowania kontaktu poza godzinami uznanymi za właściwe;</p>
                    <p>35) przyjmowania niedostosowanego do sytuacji, wyzywającego wyglądu zewnętrznego lub podejmowania prowokacyjnych zachowań;</p>
                    <p>36) publicznego komentowania wyglądu zewnętrznego innych;</p>
                    <p>37) rozm&oacute;w oraz podtekst&oacute;w i żart&oacute;w o charakterze seksualnym;</p>
                    <p>38) prezentowania i propagowania zachowań oraz postaw niezgodnych z katolicką wizją osoby;</p>
                    <p>39) używania wulgaryzm&oacute;w;</p>
                    <p>40) podnoszenia głosu bez wystarczającej przyczyny;</p>
                    <p>41) wyśmiewania rzeczy świętych;</p>
                    <p>42) prezentowania i przesyłania materiał&oacute;w zawierających treści erotyczne; pornografię, sceny przemocy lub zachowań aspołecznych;</p>
                    <p>43) podglądania, podsłuchiwania i niezgodnego z prawem wykorzystywania nagrań;</p>
                    <p>44) podszywania się pod innych, w tym kradzieży tożsamości w rozumieniu art. 190a &sect; 2 k.k.);</p>
                    <p>45) naruszania zasad przetwarzania danych osobowych Małoletnich;</p>
                    <p>46) ukrywania stanu duchownego w złym zamiarze;</p>
                    <p>47) śledzenia połączeń internetowych, zwyłączeniem sytuacji obejmujących wykonywanie zadań wskazanych w rozdziale VII;</p>
                    <p>48) używania danych kontaktowych os&oacute;b bez zachowania kościelnych i powszechnie obowiązujących przepis&oacute;w o ochronie danych osobowych;</p>
                    <p>49) łamania tajemnicy korespondencji i tajemnicy urzędowej.</p>
                    <p>&nbsp;</p>
                    <p>3. Personelowi Parafii zabrania się wobec Małoletnich stosowania przemocy fizycznej i niedozwolonych form kontaktu fizycznego, m.in.:</p>
                    <p>1) przekraczania granic intymności fizycznej w postaci podejmowania jakiejkolwiek formy kontaktu cielesnego, o ile nie wymaga tego zdrowie i życie osoby Małoletniej, lub o ile nie jest związane ze zgodnym z liturgią Kościoła sprawowaniem świętych obrzęd&oacute;w oraz przyjętymi społecznie i przez prawo Kościoła godziwymi moralnie obyczajami;</p>
                    <p>2) stosowania jakiejkolwiek przemocy, kar cielesnych i wrogich gest&oacute;w,</p>
                    <p>3) niezgodnego z obowiązującym prawem utrwalania wizerunku os&oacute;b,</p>
                    <p>4) umożliwiania oraz zachęcania i dawania przykładu zachowań mających negatywny wpływ na zdrowie lub życie, w tym palenia tytoniu, nadużywania alkoholu i używania środk&oacute;w psychoaktywnych, diet, terapii lub lek&oacute;w niezgodnie lub bez zalecenia lekarza oraz braku dbałości o higienę osobistą;</p>
                    <p>5) przewożenia os&oacute;b środkami transportu bez posiadania odpowiednich uprawnień oraz prowadzenia treningu sportowego bez posiadania uprawnień.</p>
                    <p>&nbsp;</p>
                    <p>4. Personelowi Parafii zabrania się wobec Małoletnich ponadto:</p>
                    <p>1) sprawowania sakrament&oacute;w świętych oraz praktykowania innych rodzaj&oacute;w pobożności i modlitwy poza miejscem i formą przewidzianą do tego w przepisach kościelnych bez zgody właściwego przełożonego;</p>
                    <p>2) zwielokrotniania i przedłużania spotkań z Małoletnimi oraz organizowania ich poza przyjętym wcześniej harmonogramem zawierającym informacje o czasie i miejscu spotkania bez uzasadnionej potrzeby duszpasterskiej;</p>
                    <p>3) podejmowania opieki nad Małoletnimi bez posiadania wymaganych do tego kompetencji i umiejętności, bez dopełnienia wymagań, o kt&oacute;rych mowa w art. 21 Ustawy, a także pozostając pod wpływem alkoholu lub środk&oacute;w odurzających;</p>
                    <p>4) wyciągania wobec Małoletnich sprawiających trudności wychowawcze konsekwencji dyscyplinarnych nieproporcjonalnych do przewinień i niewynikających z og&oacute;lnie przyjętych norm psychopedagogicznych;</p>
                    <p>5) zapraszania i przyjmowania Małoletnich w mieszkaniach duchownych, wyłączając spotkania o charakterze rodzinnym, a także te, kt&oacute;re są odbywane w obecności rodzic&oacute;w lub opiekun&oacute;w prawnych;</p>
                    <p>6) odbywania wizyt sam na sam w miejscu zamieszkania i pobytu Małoletniego, wyłączając wizyty o charakterze rodzinnym, wizyty za wiedzą i zgodą rodzic&oacute;w lub opiekun&oacute;w prawnych oraz wynikające ze spełniania powierzonych zgodnie z prawem obowiązk&oacute;w wychowawczych;</p>
                    <p>7) przewożenia Małoletnich własnym środkiem transportu poza sytuacją relacji rodzinnych, potrzebą wykonywania zleconych obowiązk&oacute;w wychowawczych, posiadania pisemnej zgody rodzic&oacute;w lub opiekun&oacute;w prawnych lub poza okolicznościami wymagającymi ochrony życia lub zdrowia Małoletniego;</p>
                    <p>8) uprawiania z Małoletnim indywidualnych form sportu i rekreacji, jeśli nie jest to związane z oficjalnie prowadzonym treningiem sportowym lub nie występuje za pisemną zgodą rodzic&oacute;w bądź opiekun&oacute;w prawnych;</p>
                    <p>9) zobowiązywania Małoletnich do zachowania tajemnicy poza wypadkiem tajemnicy spowiedzi lub innym przewidzianym w prawie;</p>
                    <p>10) prowadzenia rozm&oacute;w na tematy związane z dojrzewaniem i płciowością lub doświadczeniami w tej dziedzinie, wykraczających poza przewidziane dla danego wieku zatwierdzone programy edukacyjne, wychowawcze i formacyjne, a także poza potrzebą wynikającą z warunk&oacute;w sprawowania sakramentu pokuty z zachowaniem właściwych norm;</p>
                    <p>11) dzielenia się z Małoletnim wiedzą o własnych doświadczeniach w dziedzinie seksualności, a także zachowań aspołecznych i stanowiących wykroczenia przeciw prawu kanonicznemu i państwowemu;</p>
                    <p>12) namawiania os&oacute;b Małoletnich do podjęcia wobec innych os&oacute;b czynności zabronionych niniejszymi Standardami;</p>
                    <p>13) utrwalania wizerunku Małoletnich bez zgody ich rodzic&oacute;w bądź opiekun&oacute;w prawnych poza wypadkami przewidzianymi w prawie,</p>
                    <p>14) poza wypadkiem zagrożenia zdrowia lub życia asystowania Małoletnim w ich czynnościach natury osobistej, a także o ile Małoletni jest w stanie obyć się bez ich pomocy;</p>
                    <p>15) uniemożliwiania rodzicom i opiekunom prawnym wglądu w treści oraz formy pracy duszpasterskiej i wychowawczej z Małoletnimi.</p>
                    <p>&nbsp;</p>
                    <p>5. Personelowi Parafii zabrania się w korespondencji z Małoletnim:</p>
                    <p>1) uzyskiwania indywidualnych adres&oacute;w i numer&oacute;w telefonu Małoletnich bez zgody ich rodzic&oacute;w bądź opiekun&oacute;w prawnych;</p>
                    <p>2) namawiania Małoletniego do ukrywania bądź kasowania zawartości korespondencji;</p>
                    <p>3) udostępniania Małoletnim bez ważnej przyczyny osobistych środk&oacute;w komunikacji, takich jak połączenia internetowe, telefony i urządzenia mobilne, itp.</p>
                    <p>4) podejmowania bez uzasadnionej przyczyny zbyt częstych kontakt&oacute;w korespondencyjnych z Małoletnimi;</p>
                    <p>5) podejmowania temat&oacute;w związanych z dziedzinami pozostającymi w sprzeczności z nauką kościoła katolickiego, niedostosowanymi do wieku i dojrzałości Małoletnich;</p>
                    <p>6) podejmowania kontakt&oacute;w z Małoletnimi w godzinach p&oacute;źnowieczornych i wczesnorannych, poza wypadkiem zagrożenia zdrowia lub życia bądź posiadania zgody rodzic&oacute;w lub opiekun&oacute;w prawnych.</p>
                    <p>&nbsp;</p>
                    <p>6. Stawianie Małoletnim przez Personel Parafii wymagań zgodnych co do treści i formy z powszechnie obowiązującymi przepisami prawa, metodami pedagogicznymi oraz nauką Kościoła Katolickiego, a także przedstawianie możliwych konsekwencji braku zastosowania się do postawionych wymagań, nie stanowi zachowania niedozwolonego w rozumieniu ust. 2 pkt 2-3, 8-9, 21, 24.</p>
                    <p>7. Wykonywanie przez Personel Parafii zgodnych z powszechnie obowiązującymi przepisami prawa obowiązk&oacute;w związanych z opieką nad Małoletnimi, nie stanowi zachowania niedozwolonego w rozumieniu ust. 2 pkt 11, 14, 24, 33-34.</p>
                    <p>&nbsp;</p>
                    <p><strong>VI.Wymogi dotyczące bezpiecznych relacji między Małoletnimi</strong></p>
                    <p>1.Personel Parafii winien tak organizować działalność na rzecz Małoletnich, aby minimalizować wszelkie ryzyka zaistnienia sytuacji, mogących zagrozić bezpieczeństwu Małoletniego. W szczeg&oacute;lności:</p>
                    <p>1)Personelowi Parafii nie wolno pozostawiać Małoletniego powierzonego opiece bez nadzoru;</p>
                    <p>2)Personelowi Parafii nie wolno tolerować lub pozostawiać bez reakcji niedozwolonych lub niewłaściwych zachowań Małoletniego wobec innych Małoletnich;</p>
                    <p>3)Personel Parafii winien zachowywać szczeg&oacute;lną troskę wobec potrzeb Małoletnich z niepełnosprawnością lub szczeg&oacute;lnymi potrzebami edukacyjnymi.</p>
                    <p>2.Małoletni, uczestnicząc w działalności prowadzonej przez Parafię na jego rzecz, powinien:</p>
                    <p>1)zapoznać się w Wersją Skr&oacute;coną niniejszych Standard&oacute;w;</p>
                    <p>2)bezwzględnie przestrzegać poleceń Personelu Parafii, o ile są one zgodne z prawem;</p>
                    <p>3)przestrzegać warunk&oacute;w stawianych przez Parafię, związanych zjej działalnością;</p>
                    <p>4)zachowywać kulturę osobistą i przestrzegać zasad wsp&oacute;łżycia społecznego w relacjach z Personelem Parafii oraz innymi Małoletnimi.</p>
                    <p><strong>VII.Zasady korzystania z urządzeń elektronicznych z dostępem do sieci Internet przez Personel Parafii oraz przez Małoletnich, procedury ochrony Małoletnich przed treściami szkodliwymi i zagrożeniami w sieci Internet oraz utrwalonymi w innej formie</strong></p>
                    <p>1.Urządzenia elektroniczne z dostępem do sieci Internet, stanowiące własność Parafii i udostępniane Personelowi Parafii lub Małoletnim, powinny być wyposażone w zabezpieczenia chroniące je przed złośliwym oprogramowaniem oraz dostępem do treści szkodliwych dla Małoletnich.</p>
                    <p>2.Parafia zapewnia dostęp do sieci Internet za pośrednictwem operatora oferującego bezpieczny, uwierzytelniany do niego dostęp.</p>
                    <p>3.Parafia podejmuje działania zabezpieczające Małoletnich przed dostępem do treści w sieci Internet, kt&oacute;re mogą stanowić zagrożenie dla ich prawidłowego rozwoju.</p>
                    <p>4.Na urządzeniach elektronicznych z dostępem do sieci Internet, stanowiących własność Parafii, powinno być instalowane i aktualizowane oprogramowanie zabezpieczające. Parafia zachęca Personel Parafii oraz Małoletnich, korzystających z prywatnych urządzeń z dostępem do sieci Internet, do używania ich w spos&oacute;b bezpieczny i korzystania z narzędzi chroniących przed cyberprzestępczością.</p>
                    <p>5.Procedura ochrony Małoletnich przed treściami szkodliwymi i zagrożeniami w sieci Internet jest następująca:</p>
                    <p>1)Parafia, w ramach prowadzonej działalności na rzecz Małoletnich, prowadzi działania wychowawcze i edukujące odnośnie istnienia w sieci Internet treści szkodliwych oraz innych zagrożeń.</p>
                    <p>2)Personel Parafii, w przypadku korzystania przez Małoletniego ze sprzętu stanowiącego własność Parafii, czuwa nad korzystaniem z tego sprzętu i monitoruje działanie zabezpieczeń, o kt&oacute;rych mowa w ust. 1-4 zd. 1.</p>
                    <p>3)Personel Parafii zwraca uwagę Małoletniemu, korzystającemu z prywatnego urządzenia z dostępem do sieci Internet, na zagrożenia z tym związane oraz ryzyko kontaktu z treściami szkodliwymi.</p>
                    <p>4)W razie ujawnienia przez Personel Parafii kontaktu Małoletniego z treściami szkodliwymi:</p>
                    <p>a)na sprzęcie stanowiącym własność Parafii &ndash; Personel Parafii natychmiast zaprzestaje udostępniania Małoletniemu danej jednostki sprzętu i przekazuje go do uprawnionego podmiotu celem zweryfikowania prawidłowości działania zabezpieczeń, o kt&oacute;rych mowa w ust. 1-4 zd. 1.</p>
                    <p>b)na sprzęcie prywatnym Małoletniego &ndash; Personel Parafii zwraca uwagę Małoletniemu na zagrożenia związane z ekspozycją na takie treści i zaleca zaprzestanie takiego zachowania, a także, stosownie do oceny skali i powagi zagrożenia niezwłocznie, zawiadamia o nim rodzic&oacute;w lub opiekun&oacute;w Małoletniego.</p>
                    <p>5)W razie ujawnienia przez Personel Parafii poważnych zagrożeń, związanych z korzystaniem przez Małoletniego z prywatnego urządzenia elektronicznego z dostępem do sieci Internet, Personel Parafii może żądać od rodzic&oacute;w lub opiekun&oacute;w Małoletniego wyegzekwowania zaprzestania korzystania z danego urządzenia w czasie, gdy Małoletni jest odbiorcą działalności Parafii. Brak zastosowania się do powyższego żądania może stanowić podstawę zaprzestania prowadzenia działalności przez Parafię na rzecz danego Małoletniego.</p>
                    <p>6.Procedura ochrony Małoletnich przed treściami szkodliwymi i zagrożeniami utrwalonymi w formie innej niż w sieci Internet jest następująca:</p>
                    <p>1)Personel Parafii, zarządzający mieniem wykorzystywanym do prowadzenia działalności na rzecz Małoletnich jest odpowiedzialny za sprawdzanie stanu tego mienia, utrzymywanie go w czystości i porządku. W szczeg&oacute;lności konieczne jest regularne:</p>
                    <p>a)sprawdzanie przestrzeni, gdzie prowadzona jest działalność na rzecz Małoletnich pod kątem obecności nośnik&oacute;w treści szkodliwych i usuwanie ich;</p>
                    <p>b)ustalanie, w razie pojawienia się treści szkodliwych, przyczyn i os&oacute;b odpowiedzialnych za ich rozpowszechnianie;</p>
                    <p>c)zabezpieczanie przestrzeni przeznaczonej na działalność na rzecz Małoletnich przed niekontrolowanym dostępem os&oacute;b postronnych.</p>
                    <p>2)Personel Parafii, prowadzący działalność na rzecz Małoletnich w przestrzeni publicznej lub prywatnej, ale niebędącej własnością Parafii, winien tak ją organizować, aby nie narażać Małoletnich na ekspozycję na treści szkodliwe oraz inne zagrożenia.</p>
                    <p>3)W razie nieprzewidzianego pojawienia się kontaktu z treściami szkodliwymi lub innego zagrożenia Małoletnich, Personel Parafii winien natychmiastowo reagować, chroniąc Małoletnich przed ekspozycją na taki kontakt i minimalizując ryzyka z nią związane.</p>
                    <p>4)Personel Parafii, stosownie do okoliczności oraz kierując się zasadami dobrego wychowania, umiaru i taktu, może podjąć z Małoletnimi rozmowę na temat zdarzenia, wyjaśniając przyczynę podjętej interwencji oraz powody, dla kt&oacute;rych dane treści stanowią zagrożenie, przed kt&oacute;rym Małoletni muszą być chronieni.</p>
                    <p>5)Zasada, o kt&oacute;rej mowa w pkt 4, winna być stosowana z uwzględnieniem wieku Małoletniego i właściwych temu wiekowi środk&oacute;w oraz potrzeb wychowawczych.</p>
                    <p><strong>VIII.Zachowania niedozwolone Małoletnich wobec siebie i wobec Personelu Parafii</strong></p>
                    <p>Małoletniemu, w czasie korzystania z działalności organizowanej na jego rzecz przez Parafię, bezwzględnie zabrania się:</p>
                    <p>1)łamania powszechnie obowiązujących przepis&oacute;w prawa, prawa kanonicznego, niniejszych Standard&oacute;w, regulamin&oacute;w korzystania z przestrzeni, w kt&oacute;rych realizowana jest działalność na rzecz Małoletnich oraz innych regulacji wewnętrznych Parafii, z kt&oacute;rymi Małoletni został zapoznany;</p>
                    <p>2)używania ubioru lub ozd&oacute;b w spos&oacute;b mogący zagrażać zdrowiu lub życiu Małoletniego lub os&oacute;b postronnych;</p>
                    <p>3)ubierania się w spos&oacute;b nieadekwatny do okoliczności, w tym:</p>
                    <p>a. wyzywający, polegający na nadmiernym wyeksponowaniu części ciała;</p>
                    <p>b. wskazujący na przynależność do grup lub subkultur aprobujących styl życia niezgodny z prawem, nauką Kościoła i zasadami wsp&oacute;łżycia społecznego lub aprobujący działalność takich grup;</p>
                    <p>4)posiadania niebezpiecznych lub niezabezpieczonych narzędzi, mogących być źr&oacute;dłem przypadkowego lub celowego zranienia;</p>
                    <p>5)pozostawiania mienia prywatnego Małoletniego bez nadzoru;</p>
                    <p>6)palenia tytoniu, papieros&oacute;w elektronicznych oraz innych substancji o podobnym charakterze;</p>
                    <p>7)spożywania alkoholu, zażywania substancji psychoaktywnych oraz innych substancji o podobnym charakterze;</p>
                    <p>8)przebywania w stanie nietrzeźwości lub odurzenia;</p>
                    <p>9)niestosowania się do zgodnych z prawem poleceń Personelu Parafii;</p>
                    <p>10)rozpowszechniania wśr&oacute;d innych Małoletnich treści szkodliwych i narażania ich na inne zagrożenia;</p>
                    <p>11)psychomanipulacji wobec Personelu Parafii i innych Małoletnich;</p>
                    <p>12)naruszania dobrego imienia Personelu Parafii lub innego Małoletniego;</p>
                    <p>13)pomawiania Personelu Parafii lub innego Małoletniego;</p>
                    <p>14)składania fałszywych oskarżeń przeciwko Personelowi Parafii lub innemu Małoletniemu;</p>
                    <p>15)<em>stalkingu</em>(uporczywego nękania Personelu Parafii lub innego Małoletniego w rozumieniu art. 190a &sect; 1 k.k.);</p>
                    <p>16)gorszenia innego Małoletniego złym przykładem;</p>
                    <p>17)uzależniania emocjonalnego Personelu Parafii lub innego Małoletniego;</p>
                    <p>18)szantażowania Personelu Parafii lub innego Małoletniego;</p>
                    <p>19)grożenia Personelowi Parafii lub innemu Małoletniemu;</p>
                    <p>20)powoływania się na wpływy, z zastrzeżeniem, że nie dotyczy to odwoływania się do autorytetu Personelu Parafii lub Personelu Archidiecezji Krakowskiej;</p>
                    <p>21)wymuszania decyzji na Personelu Parafii lub innym Małoletnim;</p>
                    <p>22)uwodzenia Personelu Parafii lub innego Małoletniego;</p>
                    <p>23)izolowania innego Małoletniego od grupy;</p>
                    <p>24)złego traktowania Personelu Parafii lub innego Małoletniego ze względu na narodowość, rasę, płeć, wygląd, wykształcenie, poglądy polityczne, niepełnosprawność, szczeg&oacute;lne potrzeby edukacyjne lub przymioty osobowości;</p>
                    <p>25)tworzenia z Personelem Parafii lub innymi Małoletnimi środowisk o charakterze sekty;</p>
                    <p>26)bezzasadnego obdarowywania upominkami lub środkami materialnymi Personelu Parafii;</p>
                    <p>27)namawiania Personelu Parafii lub innego Małoletniego do łamania prawa państwowego lub kanonicznego;</p>
                    <p>28)poniżania Personelu Parafii lub innego Małoletniego;</p>
                    <p>29)wyśmiewania Personelu Parafii lub innego Małoletniego;</p>
                    <p>30)nieuzasadnionej krytyki Personelu Parafii lub innego Małoletniego;</p>
                    <p>31)upokarzania Personelu Parafii lub innego Małoletniego;</p>
                    <p>32)udzielania pomocy nieadekwatnej do potrzeb bliźniego;</p>
                    <p>33)stawiania innemu Małoletniemu wymagań ponad możliwości oraz kompetencje;</p>
                    <p>34)braku lub złej organizacji wsp&oacute;lnej pracy;</p>
                    <p>35)niewypełniania ważnie zawartych um&oacute;w;</p>
                    <p>36)nieuzasadnionego zobowiązywania do zachowania tajemnicy;</p>
                    <p>37)umniejszania autorytetu przełożonych i opiekun&oacute;w wobec Personelu Parafii lub innego Małoletniego;</p>
                    <p>38)nierespektowania czasu wolnego i odpoczynku Personelu Parafii i innego Małoletniego;</p>
                    <p>39)podejmowania kontaktu z Personelem Parafii lub innym Małoletnim poza godzinami uznanymi za właściwe;</p>
                    <p>40)wyzywającego wyglądu zewnętrznego lub podejmowania prowokacyjnych zachowań;</p>
                    <p>41)publicznego komentowania wyglądu zewnętrznego innych;</p>
                    <p>42)rozm&oacute;w oraz podtekst&oacute;w i żart&oacute;w o charakterze seksualnym;</p>
                    <p>43)prezentowania i propagowania zachowań oraz postaw niezgodnych z katolicką wizją osoby;</p>
                    <p>44)używania wulgaryzm&oacute;w;</p>
                    <p>45)podnoszenia głosu bez wystarczającej przyczyny;</p>
                    <p>46)wyśmiewania rzeczy świętych;</p>
                    <p>47)prezentowania i przesyłania materiał&oacute;w zawierających treści erotyczne, pornografię, sceny przemocy lub zachowań aspołecznych;</p>
                    <p>48)podglądania, podsłuchiwania i niezgodnego z prawem wykorzystywania nagrań;</p>
                    <p>49)podszywania się pod innych, w tym kradzieży tożsamości w rozumieniu art. 190a &sect; 2 k.k.;</p>
                    <p>50)śledzenia połączeń internetowych Personelu Parafii lub innego Małoletniego;</p>
                    <p>51)używania danych kontaktowych os&oacute;b bez zachowania kościelnych i państwowych przepis&oacute;w o ochronie danych osobowych;</p>
                    <p>52)łamania tajemnicy korespondencji;</p>
                    <p>53)przekraczania granic intymności fizycznej w postaci podejmowania jakiejkolwiek formy kontaktu cielesnego z Personelem Parafii lub innym Małoletnim, o ile nie wymaga tego zdrowie i życie osoby, oraz przyjętymi społecznie i przez prawo Kościoła godziwymi moralnie obyczajami;</p>
                    <p>54)stosowania jakiejkolwiek przemocy, kar cielesnych i wrogich gest&oacute;w;</p>
                    <p>55)niezgodnego z obowiązującym prawem utrwalania wizerunku os&oacute;b;</p>
                    <p>56)umożliwiania oraz zachęcania i dawania przykładu zachowań mających negatywny wpływ na zdrowie lub życie, w tym palenia tytoniu, nadużywania alkoholu i używania środk&oacute;w psychoaktywnych, diet, terapii lub lek&oacute;w niezgodnie lub bez zalecenia lekarza oraz braku dbałości o higienę osobistą.</p>
                    <p><strong>IX.Odpowiedzialność Personelu Parafii za naruszenie Standard&oacute;w</strong></p>
                    <p>1.Personel Parafii zatrudniony na podstawie um&oacute;w o pracę, za naruszenie obowiązk&oacute;w wynikających z niniejszych Standard&oacute;w ponosi odpowiedzialność porządkową na podstawie art. 108 Kodeksu pracy.</p>
                    <p>2.Osoby wchodzące w skład Personelu Parafii, pełniące posługę kapłańską na podstawie przepis&oacute;w prawa kanonicznego, za naruszenie obowiązk&oacute;w wynikających z niniejszych Standard&oacute;w podlegają odpowiedzialności i sankcjom przewidzianym w Kodeksie Prawa Kanonicznego;</p>
                    <p>3.Personel Parafii zatrudniany na podstawie um&oacute;w cywilnoprawnych, za naruszenie obowiązk&oacute;w wynikających z niniejszych Standard&oacute;w, ponosi odpowiedzialność wynikającą z treści zawartych um&oacute;w. Osoby odpowiedzialne za zawieranie um&oacute;w cywilnoprawnych w imieniu Organizatora, zobowiązane są do zamieszczania w nich klauzul obejmujących:</p>
                    <p>1)Zapoznanie się z niniejszymi Standardami oraz złożenie oświadczenia o bezwzględnym obowiązku ich stosowania;</p>
                    <p>2)Odpowiedzialność za naruszenie niniejszych Standard&oacute;w;</p>
                    <p>3)Możliwość natychmiastowego rozwiązania danej umowy z powodu naruszenia niniejszych Standard&oacute;w.</p>
                    <p>4.Niezależnie od odpowiedzialności określonej w ust. 1-4, jeżeli naruszenie obowiązk&oacute;w wynikających z niniejszych Standard&oacute;w stanowi jednocześnie naruszenie powszechnie obowiązujących przepis&oacute;w prawa lub prawa kanonicznego, Parafia ma obowiązek wsp&oacute;łpracować z właściwymi organami państwowymi i kościelnymi.</p>
                    <p><strong>X.Odpowiedzialność Małoletniego za naruszenie Standard&oacute;w</strong></p>
                    <p>1.Parafia może, wobec Małoletniego, kt&oacute;ry naruszył obowiązki wynikające z niniejszych Standard&oacute;w, zaprzestać prowadzenia działalności na jego rzecz. W takiej sytuacji Personel Parafii wzywa rodzica lub opiekuna prawnego Małoletniego i przekazuje mu Małoletniego informując o przyczynie podjęcia takiego działania. W zależności od charakteru konkretnej działalności prowadzonej na rzecz Małoletniego przez Parafię, Personel Parafii niezależnie od powyższego zobowiązany jest dopełnić r&oacute;wnież innych obowiązk&oacute;w, wynikających z regulamin&oacute;w lub um&oacute;w dotyczących danej działalności.</p>
                    <p>2.Jeżeli naruszenie przez Małoletniego obowiązk&oacute;w wynikających z niniejszych Standard&oacute;w stanowi jednocześnie naruszenie powszechnie obowiązujących przepis&oacute;w prawa, Parafia zobowiązana jest wsp&oacute;łpracować z właściwymi organami państwowymi i kościelnymi.</p>
                    <p><strong>XI.Zasady i procedura podejmowania interwencji w sytuacji podejrzenia krzywdzenia lub posiadania informacji o krzywdzeniu Małoletniego</strong></p>
                    <p>1.Personel Parafii, kt&oacute;ry uzyskał uzasadnione podejrzenie, że Małoletni był lub jest krzywdzony w związku z działalnością prowadzoną na jego rzecz przez Parafię, lub poza działalnością Parafii, zobowiązany jest niezwłocznie zawiadomić o tym fakcie osobę wskazaną w rozdziale XIII.</p>
                    <p>2.Zawiadomienie, o kt&oacute;rym mowa w ust. 1, powinno zawierać co najmniej:</p>
                    <p>1)Dane osoby (os&oacute;b) zawiadamiającej, w tym imię, nazwisko, adres do korespondencji, stanowisko i nr telefonu kontaktowego;</p>
                    <p>2)Dane Małoletniego, kt&oacute;rego dotyczy zawiadomienie, w tym informację, czy jest on osobą z niepełnosprawnością lub specjalnymi potrzebami edukacyjnymi;</p>
                    <p>3)Zwięzły opis krzywdzenia, z uwzględnieniem charakteru krzywdy (psychiczna, fizyczna, duchowa), miejsca i czasu, w kt&oacute;rym krzywdzenie miało miejsce, os&oacute;b podejrzanych;</p>
                    <p>4)Wskazanie os&oacute;b, kt&oacute;re mogą mieć dodatkowe informacje w sprawie lub innych dowod&oacute;w krzywdzenia;</p>
                    <p>5)Podpis zawiadamiającego.</p>
                    <p>3.Zawiadomienie należy sporządzić na piśmie i przekazać w zaklejonej kopercie z dopiskiem &bdquo;do rąk własnych&rdquo;. W przypadkach nagłych, zawiadomienie pisemne należy poprzedzić kontaktem telefonicznym lub mailowym; osoba wskazana w rozdziale XIII może podjąć decyzję o potrzebie osobistego kontaktu z zawiadamiającym.</p>
                    <p><strong>XII.Zasady ustalania planu wsparcia Małoletniego po ujawnieniu krzywdzenia.</strong></p>
                    <p>1.Osoba wskazana w rozdziale XIII, po otrzymaniu zawiadomienia, o kt&oacute;rym mowa w rozdziale XI, bada jego treść, zasięgając stosownie do potrzeb opinii prawnych lub opinii innych specjalist&oacute;w.</p>
                    <p>2.W razie potwierdzenia lub uprawdopodobnienia przypadku krzywdzenia, osoba wskazana w rozdziale XIII niezwłocznie ustala plan wsparcia Małoletniego i utrwala go na piśmie w formie dokumentu (przez formę pisemną należy r&oacute;wnież rozumieć dokument sporządzony w postaci elektronicznej opatrzony kwalifikowanym podpisem elektronicznym), obejmującego co najmniej:</p>
                    <p>1)Datę rejestracji zawiadomienia;</p>
                    <p>2)Opis ujawnionego przypadku krzywdzenia;</p>
                    <p>3)Imię, nazwisko, adres i numer kontaktowy:</p>
                    <p>a. Małoletniego;</p>
                    <p>b. Rodzic&oacute;w lub opiekun&oacute;w prawnych Małoletniego;</p>
                    <p>c. Pełnomocnika, jeżeli został ustanowiony.</p>
                    <p>4)Informację czy krzywdzenie może wiązać się z odpowiedzialnością odszkodowawczą Parafii lub karną Personelu Parafii;</p>
                    <p>5)Informację, czy Małoletni jest osobą z niepełnosprawnością lub specjalnymi potrzebami edukacyjnymi;</p>
                    <p>6)Rodzaj korespondencji, jaka ma zostać skierowana do organ&oacute;w ścigania, sąd&oacute;w lub organ&oacute;w administracji publicznej w związku z ujawnionym krzywdzeniem;</p>
                    <p>7)Rodzaj wsparcia, kt&oacute;ry zaoferowano w danym przypadku skrzywdzenia Małoletniego (np. instytucjonalne, duchowe, materialne);</p>
                    <p>8)Rodzaj wsparcia, kt&oacute;ry wybrano, wymiar i harmonogram jego realizacji;</p>
                    <p>9)Podpis osoby sporządzającej plan.</p>
                    <p>3.Osoba wskazana w rozdziale XIII niezwłocznie zawiadamia władze Archidiecezji Krakowskiej o potwierdzeniu lub uprawdopodobnieniu każdego przypadku krzywdzenia Małoletniego.</p>
                    <p>&nbsp;</p>
                    <p><strong>XIII.Osoby odpowiedzialne za przyjmowanie zgłoszeń o zdarzeniach zagrażających Małoletniemu i udzielanie mu wsparcia, a także odpowiedzialne za realizację procedur określonych w rozdziałach XIV i XV</strong></p>
                    <p>1. Osobą odpowiedzialną za przyjmowanie zgłoszeń, o kt&oacute;rych mowa w rozdziale XI, udzielanie wsparcia, o kt&oacute;rym mowa w rozdziale XII, przyjmowanie zgłoszeń o innych zdarzeniach zagrażających Małoletniemu, a także za realizację procedur określonych w rozdziałach XIV i XV, jest osoba zajmująca stanowisko Delegata do spraw Ochrony Dzieci i Młodzieży w Archidiecezji Krakowskiej.</p>
                    <p>2. W razie nieobecności osoby wskazanej w ust. 1, jest ona zastępowania przez osobę zajmującą stanowisko Delegatki do spraw Ochrony Dzieci i Młodzieży w Archidiecezji Krakowskiej.</p>
                    <p>3. Osoba wskazana w ust. 1 wykonuje swoje zadania wraz z osobą wskazaną w ust. 2, w szczeg&oacute;lności może powierzyć jej prowadzenie sprawy, kt&oacute;ra ze względu na swoją specyfikę wymaga prowadzenia przez osobę tej samej płci, co pokrzywdzony Małoletni.</p>
                    <p>4. Dane kontaktowe os&oacute;b wskazanych w ust. 1:</p>
                    <p>1)delegat.ochronadziecka@diecezja.pl, telefon: 724150 800;</p>
                    <p>2)delegatka.ochronadziecka@diecezja.pl, telefon: 512339339</p>
                    <p><strong>XIV.Procedura składania zawiadomień o podejrzeniu popełnienia przestępstwa na szkodę Małoletniego</strong></p>
                    <p>1.Osoba, o kt&oacute;rej mowa w rozdziale XIII, w sytuacji uznania, że w danej sprawie zachodzi podejrzenie popełnienia przestępstwa na szkodę Małoletniego, może zasięgnąć opinii prawnej, celem oceny ujawnionego zdarzenia pod kątem odpowiedzialności karnej.</p>
                    <p>2.W razie zakwalifikowania zdarzenia jako uzasadniającego podejrzenie popełnienia przestępstwa, osoba, o kt&oacute;rej mowa w rozdziale XIII sporządza, zgodnie z wymogami proceduralnymi, określonymi w k.p.k., zawiadomienie o podejrzeniu popełnienia przestępstwa i niezwłocznie kieruje je do organ&oacute;w ścigania, zachowując dow&oacute;d jego wniesienia. W sprawach nagłych zawiadomienie może być złożone ustnie, bezpośrednio do organ&oacute;w ścigania; w takim przypadku jednak osoba, o kt&oacute;rej mowa w rozdziale XIII, r&oacute;wnież zobowiązana jest do uzyskania urzędowego potwierdzenia złożenia zawiadomienia.</p>
                    <p>3.Osoba wskazana w rozdziale XIII zobowiązana jest przy wykonywaniu swoich obowiązk&oacute;w szczeg&oacute;lnie dbać o szybkość i staranność w redagowaniu i wnoszeniu pism.</p>
                    <p><strong>XV.Procedura zawiadamiania sądu opiekuńczego</strong></p>
                    <p>1.Osoba, o kt&oacute;rej mowa w rozdziale XIII, po sporządzeniu planu wsparcia Małoletniego, w sytuacji uznania, że w danej sprawie zachodzi potrzeba zawiadomienia sądu opiekuńczego, może zasięgnąć opinii prawnej, celem oceny ujawnionego zdarzenia pod wskazanym kątem (art. 572 k.p.c.).</p>
                    <p>2.W razie zakwalifikowania zdarzenia jako uzasadniającego zawiadomienie sądu opiekuńczego, osoba, o kt&oacute;rej mowa w rozdziale XIII sporządza, zgodnie z wymogami proceduralnymi, określonymi w k.p.c., zawiadomienie o zdarzeniu uzasadniającym wszczęcie postępowania z urzędu i niezwłocznie kieruje je do sądu, zachowując dow&oacute;d jego wniesienia.</p>
                    <p><strong>XVI.Spos&oacute;b dokumentowania i zasady przechowywania ujawnionych lub zgłoszonych incydent&oacute;w lub zdarzeń zagrażających dobru Małoletniego</strong></p>
                    <p>1.Osoba wskazana w rozdziale XIII gromadzi i przechowuje dokumentację związaną z danym przypadkiem krzywdzenia i realizacją planu wsparcia w czasie jego realizacji. Po zakończeniu realizacji wsparcia, dokumentacja jest przekazywana do Kancelarii Kurii Metropolitalnej (ul. Franciszkańska 3 w Krakowie) i wraz ze wszystkimi dokumentami dotyczącymi sprawy tam przechowywana z upoważnienia Parafii. Dokumentacja przechowywania jest w postaci papierowej i obejmuje w szczeg&oacute;lności:</p>
                    <p>1)Zawiadomienie, o kt&oacute;rym mowa rozdziale XI;</p>
                    <p>2)Informację o kwalifikacji zdarzenia, o kt&oacute;rej mowa w rozdziale XII ust. 1;</p>
                    <p>3)Plan wsparcia, o kt&oacute;rym mowa w rozdziale XII ust. 2;</p>
                    <p>4)Kopię zawiadomienia, o kt&oacute;rym mowa w rozdziale XII ust. 3;</p>
                    <p>5)Informację o realizacji planu, o kt&oacute;rym mowa w rozdziale XII ust. 2;</p>
                    <p>6)Kopie zawiadomień wraz z dowodami złożenia, kierowanych do organ&oacute;w i sąd&oacute;w, o kt&oacute;rych mowa w rozdziałach XIV i XV;</p>
                    <p>7)Wszelką korespondencję z rodzicami lub opiekunami Małoletniego, związaną z danym przypadkiem krzywdzenia.</p>
                    <p>2.Postanowienia ust. 1 stosuje się odpowiednio do gromadzenia i przechowywania dokumentacji związanej z innymi incydentami lub zdarzeniami zagrażającymi dobru Małoletniego.</p>
                    <p>3.Parafia ma obowiązek przechowywania dokumentacji wskazanej w ust. 1 i 2 pod wskazanym adresem, zgodnie z obowiązującymi przepisami prawa państwowego i kanonicznego.</p>
                    <p>4.Zasady przechowywania dokumentacji i przetwarzania zgromadzonych w niej danych osobowych, określa Polityka Bezpieczeństwa Danych Osobowych obowiązująca w Parafii.</p>
                    <p><strong>XVII.Zasady przeglądu i aktualizacji Standard&oacute;w</strong></p>
                    <p>1.Proboszcz Parafii, przygotowuje do 31 stycznia danego roku sprawozdanie za poprzedni rok kalendarzowy, obejmujące:</p>
                    <p>1)Ilość zgłoszonych przypadk&oacute;w krzywdzenia Małoletnich i innych incydent&oacute;w lub zdarzeń zagrażających dobru Małoletnich;</p>
                    <p>2)Rodzaj ujawnionych zagrożeń;</p>
                    <p>3)Uwagi odnośnie funkcjonowania Standard&oacute;w i efektywności procedur nimi ustanowionych.</p>
                    <p>2.Proboszcz Parafii, po sporządzeniu sprawozdania, o kt&oacute;rym mowa w ust. 1 oraz zapoznaniu się z ewentualnymi innymi informacjami o stosowaniu Standard&oacute;w, dokonuje oceny funkcjonowania Standard&oacute;w i może zmienić Standardy w trybie właściwym dla ich wprowadzenia w zależności od stwierdzonych potrzeb. Wnioski z dokonanej oceny są pisemnie dokumentowane zgodnie z art. 22c ust. 6 Ustawy i przechowywane w Kancelarii Parafialnej.</p>
                    <p><strong>XVIII. Osoba odpowiedzialna za przygotowanie Personelu Parafii do stosowania Standard&oacute;w i zakres jej kompetencji</strong></p>
                    <p>1.Osobą odpowiedzialną za przygotowanie Personelu Parafii do stosowania Standard&oacute;w jest Proboszcz Parafii.</p>
                    <p>2.Osoba, o kt&oacute;rej mowa w ust. 1, ma kompetencje do:</p>
                    <p>1)organizowania dla Personelu Parafii szkoleń, zar&oacute;wno przy wykorzystaniu własnych zasob&oacute;w, jak i szkoleniowc&oacute;w z zewnątrz;</p>
                    <p>2)przygotowywania oświadczeń o zapoznaniu się ze Standardami i ich p&oacute;źniejszymi aktualizacjami;</p>
                    <p>3)udostępniania Standard&oacute;w zgodnie z rozdziałem XX;</p>
                    <p>4)przechowywania dokumentacji, o kt&oacute;rej mowa w rozdziale XVII ust. 2.</p>
                    <p><strong>XIX.Zasady przygotowania Personelu Parafii do stosowania Standard&oacute;w i dokumentowania tej czynności</strong></p>
                    <p>1.Do 15 sierpnia 2024 r. osoba wskazana w rozdziale XVIII, zaplanuje dla całości Personelu Parafii, prowadzącego działalność na rzecz małoletnich w rozumieniu Ustawy szkolenia, niezbędne do przygotowania stosowania Standard&oacute;w. Po 15 sierpnia 2024 r. szkolenia będą przeprowadzane okresowo, w zależności od potrzeb, ale zawsze po każdorazowej aktualizacji Standard&oacute;w.</p>
                    <p>2.Personel Parafii zobowiązany jest potwierdzać obecność na szkoleniach, o kt&oacute;rych mowa w ust. 1 podpisami na listach obecności, przygotowywanych i następnie przechowywanych przez osobę wskazaną w rozdziale XVIII.</p>
                    <p><strong>XX.Zasady i spos&oacute;b udostępniania rodzicom lub opiekunom prawnym lub faktycznym oraz Małoletnim, a także Personelowi Parafii Standard&oacute;w do zaznajomienia się z nimi i ich stosowania; wersja skr&oacute;cona Standard&oacute;w.</strong></p>
                    <p>1.Niniejsze Standardy udostępnianie są przez Parafię w wersji pełnej na stronie internetowej: www.mbludzm.pl oraz wwersji papierowej, w Kancelarii Parafialnej w budynku pod adresem: Piotrowice ul.Kościelna 1 32-641 Przecisz&oacute;w. Informację w tym zakresie (przez podanie odnośnika lub zamieszczenie stosownej klauzuli) zamieszcza się r&oacute;wnież we wszelkich ogłoszeniach o konkretnych przedsięwzięciach, realizowanych przez Parafię na rzecz Małoletnich, formularzach zgłoszeniowych, regulaminach, umowach itp.</p>
                    <p>2.Wersja skr&oacute;cona Standard&oacute;w stanowi Załącznik nr 1 do niniejszych Standard&oacute;w.</p>
                    <p>3.Wersja skr&oacute;cona Standard&oacute;w udostępniana jest przez Parafię na stronie internetowej: www.mbludzm.pl oraz w wersji papierowej, we wszystkich lokalach, gdzie Parafia prowadzi działalność na rzecz Małoletnich w rozumieniu Ustawy. Parafia może r&oacute;wnież przekazywać wydruki wersji skr&oacute;conej Standard&oacute;w Małoletnim oraz ich rodzicom lub opiekunom prawnym i innym osobom w zależności od zgłoszonych lub powstałych potrzeb.</p>
                    <p>4.Parafia stosując Standardy i udostępniając ich treść dąży do zapewnienia dostępności informacyjno-komunikacyjnej osobom ze szczeg&oacute;lnymi potrzebami.</p>
                    <p>&nbsp;</p>
                    <p><em>Załączniki:</em></p>
                    <p><em>nr 1 &ndash; Wersja Skr&oacute;cona Standard&oacute;w Ochrony Małoletnich w Parafii&nbsp;</em>p.w. Nawiedzenia NMP w Piotrowicach.</p>
                    <p><em>Załącznik nr 1 do Standard&oacute;w Ochrony Małoletnich w Parafii&nbsp;</em>p.w. Nawiedzenia NMP w Piotrowicach</p>
                    <p><strong>STANDARDY OCHRONY MAŁOLETNICH W PARAFII p.w. Nawiedzenia NMP w Piotrowicach</strong></p>
                    <p><strong>WERSJA SKR&Oacute;CONA</strong></p>
                    <p><em>Wersja skr&oacute;cona Standard&oacute;w dostępna na stronie internetowej:&nbsp;</em><strong>www.nawiedzeniepiotrowice.pl</strong><strong>&nbsp;</strong><em>oraz w wersji papierowej, w Kancelarii Parafialnej w budynku pod adresem:&nbsp;</em><strong>Piotrowice ul.Kościelna 1, 32-641 Przecisz&oacute;w</strong></p>
                    <p><strong>I. Wstęp (&hellip;)</strong></p>
                    <p><strong>II.Podstawy prawne</strong></p>
                    <p>Niniejsze Standardy stworzono w oparciu o:</p>
                    <p>1) Ustawę z dnia 13 maja 2016 r. o przeciwdziałaniu zagrożeniom przestępczością na tle seksualnym i ochronie małoletnich (t.j. Dz. U. z2024 r., poz. 560) &ndash; dalej: Ustawa;</p>
                    <p>2) (&hellip;)</p>
                    <p><strong>III. Definicje</strong></p>
                    <p>Ilekroć w niniejszym dokumencie jest mowa o:</p>
                    <p>1) Standardach &ndash; należy pod tym pojęciem rozumieć standardy ochrony małoletnich w rozumieniu art. 22b i art. 22c Ustawy;</p>
                    <p>2) Działalności na rzecz Małoletnich &ndash; należy pod tym zwrotem rozumieć działalność na rzecz Małoletnich związaną z wychowaniem, edukacją, wypoczynkiem, leczeniem, świadczeniem porad psychologicznych, rozwojem duchowym, uprawianiem sportu lub realizacją innych zainteresowań przez Małoletnich, lub z opieką nad nimi w rozumieniu Ustawy;</p>
                    <p>3) Parafii lub Organizatorze &ndash; należy pod tymi pojęciami rozumieć Parafię <strong>p.w. Nawiedzenia NMP w Piotrowicach&nbsp;</strong>jako podmiot prowadzący działalność na rzecz Małoletnich w rozumieniu Ustawy i wprowadzający niniejsze Standardy;</p>
                    <p>4) Personelu Parafii &ndash; należy pod tym pojęciem rozumieć wszystkie osoby, przy pomocy kt&oacute;rych Parafia wykonuje działalność na rzecz Małoletnich, niezależnie od tego, na podstawie jakiego tytułu prawnego jest ona wykonywana;</p>
                    <p>5) Małoletnich &ndash; należy pod tym pojęciem rozumieć osoby, kt&oacute;re nie ukończyły 18 roku życia.</p>
                    <p><strong>IV. Zasady zapewniające bezpieczne relacje między Małoletnim a Personelem Parafii</strong></p>
                    <p>1.Bezpieczne relacje między Małoletnim a Personelem Parafii oparte są na następujących zasadach og&oacute;lnych:</p>
                    <p>1) Podstawą relacji między Małoletnim a Personelem Parafii, zar&oacute;wno osobistej, wsp&oacute;lnotowej, jak i podejmowanej za pomocą środk&oacute;w nawiązywania kontaktu, jest troska o prawdziwe dobro duchowe, psychiczne i fizyczne, a także respektowanie niezbywalnego prawa do poszanowania godności i potrzeba zagwarantowania bezpieczeństwa;</p>
                    <p>2) (&hellip;);</p>
                    <p>3) (&hellip;).</p>
                    <p>4) Wobec Personelu Parafii, przed dopuszczeniem do działalności na rzecz Małoletnich, Parafia wykonuje obowiązki, o kt&oacute;rych mowa wart. 21 Ustawy, w szczeg&oacute;lności weryfikuje kandydat&oacute;w do prowadzenia działalności na rzecz Małoletnich w Rejestrze Sprawc&oacute;w Przestępstw na tle Seksualnym oraz gromadzi dokumentację przedstawianą przez ww. kandydat&oacute;w.</p>
                    <p>5) Parafia, zgodnie z przepisami obowiązującego prawa kanonicznego i państwowego oraz wymogami kultury właściwej tradycji katolickiej, zabrania Personelowi Parafii stosowania wszelkich form przemocy i naruszania godności oraz d&oacute;br osobistych Małoletnich, a także podejmowania wszystkiego, co może prowadzić do takiego naruszenia, a nawet do wywołania podejrzenia lub wrażenia tego typu.</p>
                    <p>6) Dobro Małoletniego w wymiarze duchowym jest chronione przez Parafię zakazem kierowania życiem duchowym drugiego człowieka niezgodnie z nauczaniem i tradycją Kościoła Katolickiego.</p>
                    <p>7) (&hellip;).</p>
                    <p>8) Personel Parafii jest zobowiązany ze szczeg&oacute;lną uwagą, poszanowaniem i sprawiedliwością traktować własność materialną Małoletnich oraz wsp&oacute;lną, nie dopuszczając do jej niszczenia, kradzieży, wyłudzania lub przeznaczania na inne niż założone cele.</p>
                    <p>9) Personel Parafii zarządzający dobrami kościelnymi ma obowiązek utrzymywania ich w stanie, kt&oacute;ry zapewnia pełne bezpieczeństwo korzystających z nich Małoletnim.</p>
                    <p>2.Zasady komunikacji z Małoletnimi, ich rodzicami lub opiekunami Małoletnich określa się następująco:</p>
                    <p>1)O ile umożliwia to sytuacja, Personel Parafii powinien nadawać pierwszeństwo relacjom osobowym w stosunku do relacji podejmowanych za pomocą innych środk&oacute;w komunikacji, np. elektronicznej.</p>
                    <p>2)W prowadzeniu korespondencji z Małoletnimi w jakiejkolwiek formie (rozumianej jako nawiązywanie kontaktu innego niż osobisty) zalecana jest ostrożność i, o ile to możliwe, korzystanie z kont i numer&oacute;w służbowych.</p>
                    <p>3.Kontakt fizyczny (cielesny) pomiędzy Personelem Parafii, a Małoletnim, dozwolony jest wyłącznie wtedy, gdy:</p>
                    <p>1) wymaga tego ochrona zdrowia lub życia Małoletniego,</p>
                    <p>2) jest związany ze zgodnym z liturgią Kościoła sprawowaniem świętych obrzęd&oacute;w oraz przyjętymi społecznie i przez prawo Kościoła godziwymi moralnie obyczajami lub powszechnie akceptowalnymi społecznie zasadami.</p>
                    <p><strong>V.Zachowania Personelu Parafii niedozwolone wobec Małoletnich</strong></p>
                    <p>1.Personelowi Parafii zakazuje się kierować życiem Małoletniego w wymiarze duchowym niezgodnie z nauczaniem i tradycją Kościoła Katolickiego.</p>
                    <p>2.Personelowi Parafii zabrania się wobec Małoletnich stosowania przemocy psychicznej i emocjonalnej w postaci m. in.:</p>
                    <p>1) psychomanipulacji;</p>
                    <p>2)naruszania dobrego imienia;</p>
                    <p>3)pomawiania;</p>
                    <p>4)składania fałszywych oskarżeń;</p>
                    <p>5)<em>stalkingu&nbsp;</em>(uporczywego nękania innej osoby w rozumieniu art. 190a &sect; 1 k.k.);</p>
                    <p>6)gorszenia złym przykładem;</p>
                    <p>7)uzależniania emocjonalnego;</p>
                    <p>8)szantażowania;</p>
                    <p>9)grożenia;</p>
                    <p>10)powoływania się na wpływy, z zastrzeżeniem, że nie dotyczy to odwoływania do się do autorytetu dydaktycznego, wychowawczego lub moralnego innych os&oacute;b wchodzących w skład Personelu Archidiecezji Krakowskiej;</p>
                    <p>11)wymuszania decyzji;</p>
                    <p>12)intryg;</p>
                    <p>13)uwodzenia;</p>
                    <p>14)izolowania od innych;</p>
                    <p>15)złego traktowania ze względu na narodowość, rasę, płeć, wygląd, wykształcenie, poglądy polityczne, niepełnosprawność, szczeg&oacute;lne potrzeby edukacyjne lub przymioty osobowości;</p>
                    <p>16)tworzenia środowisk o charakterze sekty;</p>
                    <p>17)faworyzowania i bezzasadnego obdarowywania upominkami lub środkami materialnymi oraz przyjmowania ich;</p>
                    <p>18)nieuzasadnionych żądań finansowych;</p>
                    <p>19)namawiania do łamania prawa państwowego lub kanonicznego;</p>
                    <p>20)nieproporcjonalnego do winy oraz nieprzewidzianego w statutach karania i naznaczania poza sakramentalnych pokut;</p>
                    <p>21)poniżania;</p>
                    <p>22)wyśmiewania;</p>
                    <p>23)nieuzasadnionej krytyki;</p>
                    <p>24)upokarzania;</p>
                    <p>25)udzielania pomocy nieadekwatnej do potrzeb bliźniego;</p>
                    <p>26)stawiania wymagań ponad możliwości oraz kompetencje;</p>
                    <p>27)braku lub złej organizacji wsp&oacute;lnej pracy;</p>
                    <p>28)niewypełniania ważnie zawartych um&oacute;w;</p>
                    <p>29)łamania przewidzianej w prawie tajemnicy duszpasterskiej;</p>
                    <p>30)niezgodnego z wewnętrznymi statutami wykluczania z życia wsp&oacute;lnot;</p>
                    <p>31)nieuzasadnionego zobowiązywania do zachowania tajemnicy;</p>
                    <p>32)umniejszania autorytetu przełożonych i opiekun&oacute;w;</p>
                    <p>33)nierespektowania czasu wolnego i odpoczynku;</p>
                    <p>34)podejmowania kontaktu poza godzinami uznanymi za właściwe;</p>
                    <p>35)przyjmowania niedostosowanego do sytuacji, wyzywającego wyglądu zewnętrznego lub podejmowania prowokacyjnych zachowań;</p>
                    <p>36)publicznego komentowania wyglądu zewnętrznego innych;</p>
                    <p>37)rozm&oacute;w oraz podtekst&oacute;w i żart&oacute;w o charakterze seksualnym;</p>
                    <p>38)prezentowania i propagowania zachowań oraz postaw niezgodnych z katolicką wizją osoby;</p>
                    <p>39)używania wulgaryzm&oacute;w;</p>
                    <p>40)podnoszenia głosu bez wystarczającej przyczyny;</p>
                    <p>41)wyśmiewania rzeczy świętych;</p>
                    <p>42)prezentowania i przesyłania materiał&oacute;w zawierających treści erotyczne; pornografię, sceny przemocy lub zachowań aspołecznych;</p>
                    <p>43)podglądania, podsłuchiwania i niezgodnego z prawem wykorzystywania nagrań;</p>
                    <p>44)podszywania się pod innych, w tym kradzieży tożsamości w rozumieniu art. 190a &sect; 2 k.k.);</p>
                    <p>45)naruszania zasad przetwarzania danych osobowych Małoletnich;</p>
                    <p>46)ukrywania stanu duchownego w złym zamiarze;</p>
                    <p>47)śledzenia połączeń internetowych, z wyłączeniem sytuacji obejmujących wykonywanie zadań wskazanych w rozdziale VII;</p>
                    <p>48)używania danych kontaktowych os&oacute;b bez zachowania kościelnych i powszechnie obowiązujących przepis&oacute;w o ochronie danych osobowych;</p>
                    <p>49)łamania tajemnicy korespondencji i tajemnicy urzędowej.</p>
                    <p>3.Personelowi Parafii zabrania się wobec Małoletnich stosowania przemocy fizycznej i niedozwolonych form kontaktu fizycznego, m.in.:</p>
                    <p>1)przekraczania granic intymności fizycznej w postaci podejmowania jakiejkolwiek formy kontaktu cielesnego, o ile nie wymaga tego zdrowie i życie osoby Małoletniej, lub o ile nie jest związane ze zgodnym z liturgią Kościoła sprawowaniem świętych obrzęd&oacute;w oraz przyjętymi społecznie i przez prawo Kościoła godziwymi moralnie obyczajami;</p>
                    <p>2)stosowania jakiejkolwiek przemocy, kar cielesnych i wrogich gest&oacute;w,</p>
                    <p>3)niezgodnego z obowiązującym prawem utrwalania wizerunku os&oacute;b,</p>
                    <p>4)umożliwiania oraz zachęcania i dawania przykładu zachowań mających negatywny wpływ na zdrowie lub życie, w tym palenia tytoniu, nadużywania alkoholu i używania środk&oacute;w psychoaktywnych, diet, terapii lub lek&oacute;w niezgodnie lub bez zalecenia lekarza oraz braku dbałości o higienę osobistą;</p>
                    <p>5)przewożenia os&oacute;b środkami transportu bez posiadania odpowiednich uprawnień oraz prowadzenia treningu sportowego bez posiadania uprawnień.</p>
                    <p>4.Personelowi Parafii zabrania się wobec Małoletnich ponadto:</p>
                    <p>1)sprawowania sakrament&oacute;w świętych oraz praktykowania innych rodzaj&oacute;w pobożności i modlitwy poza miejscem i formą przewidzianą do tego w przepisach kościelnych bez zgody właściwego przełożonego;</p>
                    <p>2)zwielokrotniania i przedłużania spotkań z Małoletnimi oraz organizowania ich poza przyjętym wcześniej harmonogramem zawierającym informacje o czasie i miejscu spotkania bez uzasadnionej potrzeby duszpasterskiej;</p>
                    <p>3)podejmowania opieki nad Małoletnimi bez posiadania wymaganych do tego kompetencji i umiejętności, bez dopełnienia wymagań, o kt&oacute;rych mowa w art. 21 Ustawy, a także pozostając pod wpływem alkoholu lub środk&oacute;w odurzających;</p>
                    <p>4)wyciągania wobec Małoletnich sprawiających trudności wychowawcze konsekwencji dyscyplinarnych nieproporcjonalnych do przewinień i niewynikających z og&oacute;lnie przyjętych norm psychopedagogicznych;</p>
                    <p>5)zapraszania i przyjmowania Małoletnich w mieszkaniach duchownych, wyłączając spotkania o charakterze rodzinnym, a także te, kt&oacute;re są odbywane w obecności rodzic&oacute;w lub opiekun&oacute;w prawnych;</p>
                    <p>6)odbywania wizyt sam na sam w miejscu zamieszkania i pobytu Małoletniego, wyłączając wizyty o charakterze rodzinnym, wizyty za wiedzą i zgodą rodzic&oacute;w lub opiekun&oacute;w prawnych oraz wynikające ze spełniania powierzonych zgodnie z prawem obowiązk&oacute;w wychowawczych;</p>
                    <p>7)przewożenia Małoletnich własnym środkiem transportu poza sytuacją relacji rodzinnych, potrzebą wykonywania zleconych obowiązk&oacute;w wychowawczych, posiadania pisemnej zgody rodzic&oacute;w lub opiekun&oacute;w prawnych lub poza okolicznościami wymagającymi ochrony życia lub zdrowia Małoletniego;</p>
                    <p>8)uprawiania z Małoletnim indywidualnych form sportu i rekreacji, jeśli nie jest to związane z oficjalnie prowadzonym treningiem sportowym lub nie występuje za pisemną zgodą rodzic&oacute;w bądź opiekun&oacute;w prawnych;</p>
                    <p>9)zobowiązywania Małoletnich do zachowania tajemnicy poza wypadkiem tajemnicy spowiedzi lub innym przewidzianym w prawie;</p>
                    <p>10)prowadzenia rozm&oacute;w na tematy związane z dojrzewaniem i płciowością lub doświadczeniami w tej dziedzinie, wykraczających poza przewidziane dla danego wieku zatwierdzone programy edukacyjne, wychowawcze i formacyjne, a także poza potrzebą wynikającą z warunk&oacute;w sprawowania sakramentu pokuty z zachowaniem właściwych norm;</p>
                    <p>11)dzielenia się z Małoletnim wiedzą o własnych doświadczeniach w&nbsp; dziedzinie seksualności, a także zachowań aspołecznych i stanowiących wykroczenia przeciw prawu kanonicznemu i państwowemu;</p>
                    <p>12)namawiania os&oacute;b Małoletnich do podjęcia wobec innych os&oacute;b czynności zabronionych niniejszymi Standardami;</p>
                    <p>13)utrwalania wizerunku Małoletnich bez zgody ich rodzic&oacute;w bądź opiekun&oacute;w prawnych poza wypadkami przewidzianymi w prawie,</p>
                    <p>14)poza wypadkiem zagrożenia zdrowia lub życia asystowania Małoletnim w ich czynnościach natury osobistej, a także o ile Małoletni jest w stanie obyć się bez ich pomocy;</p>
                    <p>15)uniemożliwiania rodzicom i opiekunom prawnym wglądu w treści oraz formy pracy duszpasterskiej i wychowawczej z Małoletnimi.</p>
                    <p>5.Personelowi Parafii zabrania się w korespondencji z Małoletnim:</p>
                    <p>1)uzyskiwania indywidualnych adres&oacute;w i numer&oacute;w telefonu Małoletnich bez zgody ich rodzic&oacute;w bądź opiekun&oacute;w prawnych;</p>
                    <p>2)namawiania Małoletniego do ukrywania bądź kasowania zawartości korespondencji;</p>
                    <p>3)udostępniania Małoletnim bez ważnej przyczyny osobistych środk&oacute;w komunikacji, takich jak połączenia internetowe, telefony i urządzenia mobilne, itp.</p>
                    <p>4)podejmowania bez uzasadnionej przyczyny zbyt częstych kontakt&oacute;w korespondencyjnych z Małoletnimi;</p>
                    <p>5)podejmowania temat&oacute;w związanych z dziedzinami pozostającymi w sprzeczności z nauką kościoła katolickiego, niedostosowanymi do wieku i dojrzałości Małoletnich;</p>
                    <p>6)podejmowania kontakt&oacute;w z Małoletnimi w godzinach p&oacute;źnowieczornych i wczesnorannych, poza wypadkiem zagrożenia zdrowia lub życia bądź posiadania zgody rodzic&oacute;w lub opiekun&oacute;w prawnych.</p>
                    <p>6.Stawianie Małoletnim przez Personel Parafii wymagań zgodnych co do treści i formy z powszechnie obowiązującymi przepisami prawa, metodami pedagogicznymi oraz nauką Kościoła Katolickiego, a także przedstawianie możliwych konsekwencji braku zastosowania się do postawionych wymagań, nie stanowi zachowania niedozwolonego w rozumieniu ust. 2 pkt 2-3, 8-9, 21, 24.</p>
                    <p>7.Wykonywanie przez Personel Parafii zgodnych z powszechnie obowiązującymi przepisami prawa obowiązk&oacute;w związanych z opieką nad Małoletnimi, nie stanowi zachowania niedozwolonego w rozumieniu ust. 2 pkt 11, 14, 24, 33-34.</p>
                    <p><strong>VI.Wymogi dotyczące bezpiecznych relacji między Małoletnimi</strong></p>
                    <p>1.Personel Parafii winien tak organizować działalność na rzecz Małoletnich, aby minimalizować wszelkie ryzyka zaistnienia sytuacji, mogących zagrozić bezpieczeństwu Małoletniego. W szczeg&oacute;lności:</p>
                    <p>1) Personelowi Parafii nie wolno pozostawiać Małoletniego powierzonego opiece bez nadzoru;</p>
                    <p>2) Personelowi Parafii nie wolno tolerować lub pozostawiać bez reakcji niedozwolonych lub niewłaściwych zachowań Małoletniego wobec innych Małoletnich;</p>
                    <p>3) Personel Parafii winien zachowywać szczeg&oacute;lną troskę wobec potrzeb Małoletnich&nbsp; z niepełnosprawnością lub szczeg&oacute;lnymi potrzebami edukacyjnymi.</p>
                    <p>2.Małoletni, uczestnicząc w działalności prowadzonej przez Parafię na jego rzecz, powinien:</p>
                    <p>1) zapoznać się w Wersją Skr&oacute;coną niniejszych Standard&oacute;w;</p>
                    <p>2) bezwzględnie przestrzegać poleceń Personelu Parafii, o ile są one zgodne z prawem;</p>
                    <p>3) przestrzegać warunk&oacute;w stawianych przez Parafię, związanych z jej działalnością;</p>
                    <p>4) zachowywać kulturę osobistą i przestrzegać zasad wsp&oacute;łżycia społecznego w relacjach z Personelem Parafii oraz innymi Małoletnimi.</p>
                    <p><strong>VII. Zasady korzystania z urządzeń elektronicznych z dostępem do sieci Internet przez Personel Parafii oraz przez Małoletnich, procedury ochrony Małoletnich przed treściami szkodliwymi i zagrożeniami w sieci Internet oraz utrwalonymi w innej formie</strong></p>
                    <p>1.Urządzenia elektroniczne z dostępem do sieci Internet, stanowiące własność Parafii i udostępniane Personelowi Parafii lub Małoletnim, powinny być wyposażone w zabezpieczenia chroniące je przed złośliwym oprogramowaniem oraz dostępem do treści szkodliwych dla Małoletnich.</p>
                    <p>2.Parafia zapewnia dostęp do sieci Internet za pośrednictwem operatora oferującego bezpieczny, uwierzytelniany do niego dostęp.</p>
                    <p>3.Parafia podejmuje działania zabezpieczające Małoletnich przed dostępem do treści w sieci Internet, kt&oacute;re mogą stanowić zagrożenie dla ich prawidłowego rozwoju.</p>
                    <p>4.Na urządzeniach elektronicznych z dostępem do sieci Internet, stanowiących własność Parafii, powinno być instalowane i aktualizowane oprogramowanie zabezpieczające. Parafia zachęca Personel Parafii oraz Małoletnich, korzystających z prywatnych urządzeń z dostępem do sieci Internet, do używania ich w spos&oacute;b bezpieczny i korzystania z narzędzi chroniących przed cyberprzestępczością.</p>
                    <p>5.Procedura ochrony Małoletnich przed treściami szkodliwymi i zagrożeniami w sieci Internet (&hellip;).</p>
                    <p>6.Procedura ochrony Małoletnich przed treściami szkodliwymi i zagrożeniami utrwalonymi w formie innej niż w sieci Internet (&hellip;).</p>
                    <p><strong>VIII.Zachowania niedozwolone Małoletnich wobec siebie i wobec Personelu Parafii</strong></p>
                    <p>Małoletniemu, w czasie korzystania z działalności organizowanej na jego rzecz przez Parafię, bezwzględnie zabrania się:</p>
                    <p>1) łamania powszechnie obowiązujących przepis&oacute;w prawa, prawa kanonicznego, niniejszych Standard&oacute;w, regulamin&oacute;w korzystania z przestrzeni, w kt&oacute;rych realizowana jest działalność na rzecz Małoletnich oraz innych regulacji wewnętrznych Parafii, z kt&oacute;rymi Małoletni został zapoznany;</p>
                    <p>2) używania ubioru lub ozd&oacute;b w spos&oacute;b mogący zagrażać zdrowiu lub życiu Małoletniego lub os&oacute;b postronnych;</p>
                    <p>3) ubierania się w spos&oacute;b nieadekwatny do okoliczności, w tym:</p>
                    <p>a. wyzywający, polegający na nadmiernym wyeksponowaniu części ciała;</p>
                    <p>b. wskazujący na przynależność do grup lub subkultur aprobujących styl życia niezgodny z prawem, nauką Kościoła i zasadami wsp&oacute;łżycia społecznego lub aprobujący działalność takich grup;</p>
                    <p>4) posiadania niebezpiecznych lub niezabezpieczonych narzędzi, mogących być źr&oacute;dłem przypadkowego lub celowego zranienia;</p>
                    <p>5) pozostawiania mienia prywatnego Małoletniego bez nadzoru;</p>
                    <p>6) palenia tytoniu, papieros&oacute;w elektronicznych oraz innych substancji opodobnym charakterze;</p>
                    <p>7) spożywania alkoholu, zażywania substancji psychoaktywnych oraz innych substancji o podobnym charakterze;</p>
                    <p>8) przebywania w stanie nietrzeźwości lub odurzenia;</p>
                    <p>9) niestosowania się do zgodnych z prawem poleceń Personelu Parafii;</p>
                    <p>10) rozpowszechniania wśr&oacute;d innych Małoletnich treści szkodliwych i narażania ich na inne zagrożenia;</p>
                    <p>11) psychomanipulacji wobec Personelu Parafii i innych Małoletnich;</p>
                    <p>12) naruszania dobrego imienia Personelu Parafii lub innego Małoletniego;</p>
                    <p>13) pomawiania Personelu Parafii lub innego Małoletniego;</p>
                    <p>14) składania fałszywych oskarżeń przeciwko Personelowi Parafii lub innemu Małoletniemu;</p>
                    <p>15)<em>stalkingu&nbsp;</em>(uporczywego nękania Personelu Parafii lub innego Małoletniego w rozumieniu art. 190 a &sect; 1 k.k.);</p>
                    <p>16) gorszenia innego Małoletniego złym przykładem;</p>
                    <p>17) uzależniania emocjonalnego Personelu Parafii lub innego Małoletniego;</p>
                    <p>18) szantażowania Personelu Parafii lub innego Małoletniego;</p>
                    <p>19) grożenia Personelowi Parafii lub innemu Małoletniemu;</p>
                    <p>20) powoływania się na wpływy, z zastrzeżeniem, że nie dotyczy to odwoływania się do autorytetu Personelu Parafii lub Personelu Archidiecezji Krakowskiej;</p>
                    <p>21) wymuszania decyzji na Personelu Parafii lub innym Małoletnim;</p>
                    <p>22) uwodzenia Personelu Parafii lub innego Małoletniego;</p>
                    <p>23) izolowania innego Małoletniego od grupy;</p>
                    <p>24) złego traktowania Personelu Parafii lub innego Małoletniego ze względu na narodowość, rasę, płeć, wygląd, wykształcenie, poglądy polityczne, niepełnosprawność, szczeg&oacute;lne potrzeby edukacyjne lub przymioty osobowości;</p>
                    <p>25) tworzenia z Personelem Parafii lub innymi Małoletnimi środowisk o charakterze sekty;</p>
                    <p>26) bezzasadnego obdarowywania upominkami lub środkami materialnymi Personelu Parafii;</p>
                    <p>27) namawiania Personelu Parafii lub innego Małoletniego do łamania prawa państwowego lub kanonicznego;</p>
                    <p>28) poniżania Personelu Parafii lub innego Małoletniego;</p>
                    <p>29) wyśmiewania Personelu Parafii lub innego Małoletniego;</p>
                    <p>30) nieuzasadnionej krytyki Personelu Parafii lub innego Małoletniego;</p>
                    <p>31) upokarzania Personelu Parafii lub innego Małoletniego;</p>
                    <p>32) udzielania pomocy nieadekwatnej do potrzeb bliźniego;</p>
                    <p>33) stawiania innemu Małoletniemu wymagań ponad możliwości oraz kompetencje;</p>
                    <p>34) braku lub złej organizacji wsp&oacute;lnej pracy;</p>
                    <p>35) niewypełniania ważnie zawartych um&oacute;w;</p>
                    <p>36) nieuzasadnionego zobowiązywania do zachowania tajemnicy;</p>
                    <p>37) umniejszania autorytetu przełożonych i opiekun&oacute;w wobec Personelu Parafii lub innego Małoletniego;</p>
                    <p>38) nierespektowania czasu wolnego i odpoczynku Personelu Parafii i innego Małoletniego;</p>
                    <p>39) podejmowania kontaktu z Personelem Parafii lub innym Małoletnim poza godzinami uznanymi za właściwe;</p>
                    <p>40) wyzywającego wyglądu zewnętrznego lub podejmowania prowokacyjnych zachowań;</p>
                    <p>41) publicznego komentowania wyglądu zewnętrznego innych;</p>
                    <p>42) rozm&oacute;w oraz podtekst&oacute;w i żart&oacute;w o charakterze seksualnym;</p>
                    <p>43) prezentowania i propagowania zachowań oraz postaw niezgodnych z katolicką wizją osoby;</p>
                    <p>44) używania wulgaryzm&oacute;w;</p>
                    <p>45) podnoszenia głosu bez wystarczającej przyczyny;</p>
                    <p>46) wyśmiewania rzeczy świętych;</p>
                    <p>47) prezentowania i przesyłania materiał&oacute;w zawierających treści erotyczne, pornografię, sceny przemocy lub zachowań aspołecznych;</p>
                    <p>48) podglądania, podsłuchiwania i niezgodnego z prawem wykorzystywania nagrań;</p>
                    <p>49) podszywania się pod innych, w tym kradzieży tożsamości w rozumieniu art. 190a &sect; 2 k.k.;</p>
                    <p>50) śledzenia połączeń internetowych Personelu Parafii lub innego Małoletniego;</p>
                    <p>51) używania danych kontaktowych os&oacute;b bez zachowania kościelnych i państwowych przepis&oacute;w o ochronie danych osobowych;</p>
                    <p>52) łamania tajemnicy korespondencji;</p>
                    <p>53) przekraczania granic intymności fizycznej w postaci podejmowania jakiejkolwiek formy kontaktu cielesnego z Personelem Parafii lub innym Małoletnim, o ile nie wymaga tego zdrowie i życie osoby, oraz przyjętymi społecznie i przez prawo Kościoła godziwymi moralnie obyczajami;</p>
                    <p>54) stosowania jakiejkolwiek przemocy, kar cielesnych i wrogich gest&oacute;w;</p>
                    <p>55) niezgodnego z obowiązującym prawem utrwalania wizerunku os&oacute;b;</p>
                    <p>56) umożliwiania oraz zachęcania i dawania przykładu zachowań mających negatywny wpływ na zdrowie lub życie, w tym palenia tytoniu, nadużywania alkoholu i używania środk&oacute;w psychoaktywnych, diet, terapii lub lek&oacute;w niezgodnie lub bez zalecenia lekarza oraz braku dbałości o higienę osobistą.</p>
                    <p><strong>IX.(&hellip;)</strong></p>
                    <p><strong>X.Odpowiedzialność Małoletniego za naruszenie Standard&oacute;w</strong></p>
                    <p>1.Parafia może, wobec Małoletniego, kt&oacute;ry naruszył obowiązki wynikające z niniejszych Standard&oacute;w, zaprzestać prowadzenia działalności na jego rzecz. W takiej sytuacji Personel Parafii wzywa rodzica lub opiekuna prawnego Małoletniego i przekazuje mu Małoletniego informując o przyczynie podjęcia takiego działania. W zależności od charakteru konkretnej działalności prowadzonej na rzecz Małoletniego przez Parafię, Personel Parafii niezależnie od powyższego zobowiązany jest dopełnić r&oacute;wnież innych obowiązk&oacute;w, wynikających z regulamin&oacute;w lub um&oacute;w dotyczących danej działalności.</p>
                    <p>2.Jeżeli naruszenie przez Małoletniego obowiązk&oacute;w wynikających z niniejszych Standard&oacute;w stanowi jednocześnie naruszenie powszechnie obowiązujących przepis&oacute;w prawa, Parafia zobowiązana jest wsp&oacute;łpracować zwłaściwymi organami państwowymi i kościelnymi.</p>
                    <p><strong>XI.(&hellip;)</strong></p>
                    <p>1.(&hellip;).</p>
                    <p><strong>XII.(&hellip;).</strong></p>
                    <p>1.(&hellip;).</p>
                    <p><strong>XIII.Osoby odpowiedzialne za przyjmowanie zgłoszeń o zdarzeniach zagrażających Małoletniemu i udzielanie mu wsparcia, a także odpowiedzialne za realizację procedur określonych w rozdziałach XIV i XV</strong></p>
                    <p>1. Osobą odpowiedzialną za przyjmowanie zgłoszeń, o kt&oacute;rych mowa w rozdziale XI, udzielanie wsparcia, o kt&oacute;rym mowa w rozdziale XII, przyjmowanie zgłoszeń o innych zdarzeniach zagrażających Małoletniemu, a także za realizację procedur określonych w rozdziałach XIV i XV, jest osoba zajmująca stanowisko Delegata do spraw Ochrony Dzieci i Młodzieży w Archidiecezji Krakowskiej.</p>
                    <p>2. W razie nieobecności osoby wskazanej w ust. 1, jest ona zastępowania przez osobę zajmującą stanowisko Delegatki do spraw Ochrony Dzieci i Młodzieży w Archidiecezji Krakowskiej.</p>
                    <p>3. Osoba wskazana w ust. 1 wykonuje swoje zadania wraz z osobą wskazaną w ust. 2, w szczeg&oacute;lności może powierzyć jej prowadzenie sprawy, kt&oacute;ra ze względu na swoją specyfikę wymaga prowadzenia przez osobę tej samej płci, co pokrzywdzony Małoletni.</p>
                    <p>4. Dane kontaktowe os&oacute;b wskazanych w ust. 1:</p>
                    <p>1)delegat.ochronadziecka@diecezja.pl, telefon: 724 150 800;</p>
                    <p>2)delegatka.ochronadziecka@diecezja.pl, telefon: 512 339 339</p>
                    <p><strong>XIV. (&hellip;)</strong></p>
                    <p>1.(&hellip;).</p>
                    <p><strong>XV. (&hellip;)</strong></p>
                    <p>1.(&hellip;).</p>
                    <p><strong>XVI.Spos&oacute;b dokumentowania i zasady przechowywania ujawnionych lub zgłoszonych incydent&oacute;w lub zdarzeń zagrażających dobru Małoletniego</strong></p>
                    <p>1.Osoba wskazana w rozdziale XIII gromadzi i przechowuje dokumentację związaną z danym przypadkiem krzywdzenia i realizacją planu wsparcia w czasie jego realizacji. Po zakończeniu realizacji wsparcia, dokumentacja jest przekazywana do Kancelarii Kurii Metropolitalnej (ul. Franciszkańska 3 w Krakowie) i wraz ze wszystkimi dokumentami dotyczącymi sprawy tam przechowywana z upoważnienia Parafii. Dokumentacja przechowywania jest w postaci papierowej i obejmuje w szczeg&oacute;lności:</p>
                    <p>1) Zawiadomienie, o kt&oacute;rym mowa rozdziale XI;</p>
                    <p>2) Informację o kwalifikacji zdarzenia, o kt&oacute;rej mowa w rozdziale XII ust. 1;</p>
                    <p>3) Plan wsparcia, o kt&oacute;rym mowa w rozdziale XII ust. 2;</p>
                    <p>4) Kopię zawiadomienia, o kt&oacute;rym mowa w rozdziale XII ust. 3;</p>
                    <p>5) Informację o realizacji planu, o kt&oacute;rym mowa w rozdziale XII ust. 2;</p>
                    <p>6) Kopie zawiadomień wraz z dowodami złożenia, kierowanych do organ&oacute;w i sąd&oacute;w, o kt&oacute;rych mowa w rozdziałach XIV i XV;</p>
                    <p>7) Wszelką korespondencję z rodzicami lub opiekunami Małoletniego, związaną z danym przypadkiem krzywdzenia.</p>
                    <p>2.Postanowienia ust. 1 stosuje się odpowiednio do gromadzenia i przechowywania dokumentacji związanej z innymi incydentami lub zdarzeniami zagrażającymi dobru Małoletniego.</p>
                    <p>3.Parafia ma obowiązek przechowywania dokumentacji wskazanej w ust. 1 i 2 pod wskazanym adresem, zgodnie z obowiązującymi przepisami prawa państwowego i kanonicznego.</p>
                    <p><strong>XVII.(&hellip;)</strong></p>
                    <p>1.(&hellip;).</p>
                    <p><strong>XVIII.(&hellip;)</strong></p>
                    <p>1.(&hellip;)</p>
                    <p>&nbsp;</p>
                    <p><strong>XIX.(&hellip;)</strong></p>
                    <p>1.(&hellip;).</p>
                    <p><strong>XX. Zasady i spos&oacute;b udostępniania rodzicom lub opiekunom prawnym lub faktycznym oraz Małoletnim, a także Personelowi Parafii Standard&oacute;w do zaznajomienia się z nimi i ich stosowania; wersja skr&oacute;cona Standard&oacute;w.</strong></p>
                    <p>1.Niniejsze Standardy udostępnianie są przez Parafię w wersji pełnej na stronie internetowej: <strong>www.nawiedzeniepiotrowice.pl</strong><strong>&nbsp;</strong>oraz w wersji papierowej, w Kancelarii Parafialnej w budynku pod adresem: <strong>ul. Kościelna 1 32 - 641 Piotrowice</strong>. Informację w tym zakresie (przez podanie odnośnika lub zamieszczenie stosownej klauzuli) zamieszcza się r&oacute;wnież we wszelkich ogłoszeniach o konkretnych przedsięwzięciach, realizowanych przez Parafię na rzecz Małoletnich, formularzach zgłoszeniowych, regulaminach, umowach itp.</p>
                    <p>2.Wersja skr&oacute;cona Standard&oacute;w stanowi Załącznik nr 1 do Standard&oacute;w.</p>
                    <p>3.Wersja skr&oacute;cona Standard&oacute;w udostępniana jest przez Parafię na stronie internetowej: www.nawiedzeniepiotrowice.pl oraz w wersji papierowej, we wszystkich lokalach, gdzie Parafia prowadzi działalność na rzecz Małoletnich w rozumieniu Ustawy. Parafia może r&oacute;wnież przekazywać wydruki wersji skr&oacute;conej Standard&oacute;w Małoletnim oraz ich rodzicom lub opiekunom prawnym i innym osobom w zależności od zgłoszonych lub powstałych potrzeb.</p>
                    <p>4.(&hellip;).</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
            </div>
            <Footer />
        </>
    )
}

export default StandardyOchronyMałoletnich