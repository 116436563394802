import '../App.css'
import Navigation from '../componets/Navigation'
import Footer from '../componets/Footer'
import './repeat.css'

function Ofiarodawcy(){
    return (
        <>
            <Navigation />
            <div className='article'>
                <div className='title'>OFIARA  NA POTRZEBY  KOŚCIOŁA LISTOPAD  2024</div>
                <table>
                    <tr><td>1</td><td>Brzeźniak</td><td>Łowiczki Graniczna 38</td><td>50zł</td></tr>
                    <tr><td>2</td><td>R.T.Drzyżdżyk</td><td>Łowiczki</td><td>100zł</td></tr>
                    <tr><td>3</td><td>L.P.Gurdek</td><td>Łowiczki Centralna 212</td><td>100zł</td></tr>
                    <tr><td>4</td><td>B.B.Jezierscy</td><td>Łowiczki Centralna 194</td><td>50zł</td></tr>
                    <tr><td>5</td><td>Helena Jurkowska</td><td>Łowiczki Centralna 212</td><td>100zł</td></tr>
                    <tr><td>6</td><td>Wojciech Kajdas</td><td>Łowiczki</td><td>200zł</td></tr>
                    <tr><td>7</td><td>I.Miętka</td><td>Łowiczki</td><td>100zł</td></tr>
                    <tr><td>8</td><td>Renata Pękala</td><td>Łowiczki</td><td>100zł</td></tr>
                    <tr><td>9</td><td>Jolanta Semik</td><td>Łowiczki Jodłowa 26</td><td>2000zł</td></tr>
                    <tr><td>10</td><td>M.J.Seremet</td><td>Łowiczki</td><td>200zł</td></tr>
                    <tr><td>11</td><td>H.K.Stachura</td><td>Łowiczki</td><td>50zł</td></tr>
                    <tr><td>12</td><td>B.A.Wojtaszek</td><td>Łowiczki</td><td>100zł</td></tr>
                    <tr><td>13</td><td>Jerzy Wójcik</td><td>Łowiczki Cyprysowa 15</td><td>100zł</td></tr>
                    <tr><td>14</td><td>M.L.Anteccy</td><td>Andrychowska 13</td><td>100zł</td></tr>
                    <tr><td>15</td><td>Bernaś, Momot</td><td>Akacjowa 31</td><td>100zł</td></tr>
                    <tr><td>16</td><td>Maria Stanisław Boba</td><td>Andrychowska 352</td><td>100zł</td></tr>
                    <tr><td>17</td><td>B.B.Cuber</td><td></td><td>100zł</td></tr>
                    <tr><td>18</td><td>Krystyna Domider</td><td></td><td>100zł</td></tr>
                    <tr><td>19</td><td>Czesław Frączek</td><td>Andrychowska 21</td><td>100zł</td></tr>
                    <tr><td>20</td><td>I.S.Frączek</td><td>Andrychowska 163</td><td>100zł</td></tr>
                    <tr><td>21</td><td>S.D.Frączek</td><td>Andrychowska 362</td><td>100zł</td></tr>
                    <tr><td>22</td><td>Marcin Gagracz</td><td>Akacjowa 96</td><td>100zł</td></tr>
                    <tr><td>23</td><td>Czesława Gluzicka</td><td>Akacjowa 86</td><td>50zł</td></tr>
                    <tr><td>24</td><td>Józef Głąb</td><td>Modrzewiowa</td><td>200zł</td></tr>
                    <tr><td>25</td><td>W.M.Gorzkowscy</td><td></td><td>100zł</td></tr>
                    <tr><td>26</td><td>Grochal</td><td>Akacjowa 117</td><td>200zł</td></tr>
                    <tr><td>27</td><td>R.Leszek Janik</td><td>Pagórkowa 19</td><td>100zł</td></tr>
                    <tr><td>28</td><td>Jarosz</td><td>Andrychowska 32</td><td>100zł</td></tr>
                    <tr><td>29</td><td>Renata Kobylańska</td><td></td><td>50zł</td></tr>
                    <tr><td>30</td><td>Kocjan</td><td></td><td>100zł</td></tr>
                    <tr><td>31</td><td>Kołodziej</td><td>Lipowa 59</td><td>100zł</td></tr>
                    <tr><td>32</td><td>E.P.Kozak</td><td>Andrychowska 233</td><td>100zł</td></tr>
                    <tr><td>33</td><td>A.K.Krawczyk</td><td>Akacjowa</td><td>100zł</td></tr>
                    <tr><td>34</td><td>Kuska, Opalach</td><td></td><td>150zł</td></tr>
                    <tr><td>35</td><td>Stanisław Kuska</td><td>Kasztanowa 4</td><td>50zł</td></tr>
                    <tr><td>36</td><td>M.A.Kuśpik</td><td>Andrychowska 174</td><td>100zł</td></tr>
                    <tr><td>37</td><td>B.A.Kuwik</td><td>Lipowa 57</td><td>100zł</td></tr>
                    <tr><td>38</td><td>Krystian Luranc</td><td>Andrychowska 152</td><td>100zł</td></tr>
                    <tr><td>39</td><td>Sebastian Makuch</td><td></td><td>300zł</td></tr>
                    <tr><td>40</td><td>Michalska</td><td></td><td>100zł</td></tr>
                    <tr><td>41</td><td>Stanisław Młynarczyk</td><td>Akacjowa 56</td><td>100zł</td></tr>
                    <tr><td>42</td><td>Wiktoria Noworyta</td><td></td><td>50zł</td></tr>
                    <tr><td>43</td><td>S.S.Patrzyk</td><td>Kasztanowa</td><td>200zł</td></tr>
                    <tr><td>44</td><td>M.M.Płachta</td><td>Bukowiecka 52</td><td>100zł</td></tr>
                    <tr><td>45</td><td>Płonka</td><td>Andrychowska 299</td><td>100zł</td></tr>
                    <tr><td>46</td><td>Rozalia Rola</td><td>Andrychowska 225</td><td>100zł</td></tr>
                    <tr><td>47</td><td>I.S.</td><td></td><td>200zł</td></tr>
                    <tr><td>48</td><td>Szatan</td><td>Andrychowska 272</td><td>100zł</td></tr>
                    <tr><td>49</td><td>Łukasz Wiśniecki</td><td>Lipowa 43</td><td>400zł</td></tr>
                    <tr><td>50</td><td>Wnętrzak</td><td></td><td>200zł</td></tr>
                    <tr><td>51</td><td>Wojtala</td><td>Andrychowska 172</td><td>50zł</td></tr>
                    <tr><td>52</td><td>Józef Wróbel</td><td>Węglarzówka 2</td><td>100zł</td></tr>
                    <tr><td>53</td><td>J.K.Zając</td><td>Andrychowska 101</td><td>100zł</td></tr>
                    <tr><td>54</td><td>Krzysztof Zając</td><td>Pagórkowa</td><td>200zł</td></tr>
                    <tr><td>55</td><td>Zdźalik</td><td>Wrzosowa 25</td><td>50zł</td></tr>
                    <tr><td>56</td><td>Urszula Mieczysław Żabińscy</td><td>Andrychowska 340</td><td>100zł</td></tr>
                    <tr><td>57</td><td></td><td>Akacjowa 24</td><td>100zł</td></tr>
                    <tr><td>58</td><td></td><td>Andrychowska 164</td><td>200zł</td></tr>
                    <tr><td>59</td><td></td><td>Andrychowska 278</td><td>50zł</td></tr>
                    <tr><td>60</td><td></td><td>Kasztanowa 10</td><td>300zł</td></tr>
                    <tr><td>61</td><td></td><td>Kasztanowa 49</td><td>50zł</td></tr>
                    <tr><td>62</td><td></td><td>Kościelna 16</td><td>50zł</td></tr>
                    <tr><td>63</td><td></td><td>Lipowa 22</td><td>50zł</td></tr>
                    <tr><td>64</td><td></td><td>Wrzosowa 27</td><td>50zł</td></tr>
                    <tr><td>65</td><td>Anonimowo</td><td></td><td>1000zł</td></tr>
                </table>
            </div>
            <div className='article'>
                <div className='title'>OFIARA NA OGRZEWANIA I ENERGIĘ 2025</div>
                <table>
                    <tr><td>1</td><td>Brzeźniak</td><td>Łowiczki Graniczna 38</td><td>50zł</td></tr>
                    <tr><td>2</td><td>Anna Artur Dąbrowscy</td><td>Łowiczki Centralna 228</td><td>50zł</td></tr>
                    <tr><td>3</td><td>Czesław Dąbrowski</td><td>Łowiczki Centralna 228</td><td>50zł</td></tr>
                    <tr><td>4</td><td>Dorota Dabrowska</td><td>Łowiczki</td><td>50zł</td></tr>
                    <tr><td>5</td><td>Halina Marek Dąbrowscy</td><td>Łowiczki Centralna 228</td><td>100zł</td></tr>
                    <tr><td>6</td><td>Józefa Domagała</td><td>Łowiczki</td><td>50zł</td></tr>
                    <tr><td>7</td><td>J.J.Głąb</td><td>Łowiczki</td><td>50zł</td></tr>
                    <tr><td>8</td><td>Dorota Leszek Majkut</td><td>Łowiczki</td><td>100zł</td></tr>
                    <tr><td>9</td><td>Matyjasik, Śpiewla</td><td>Łowiczki</td><td>50zł</td></tr>
                    <tr><td>10</td><td>J.J.Sroczyńscy</td><td>Łowiczki Jodłowa 9</td><td>100zł</td></tr>
                    <tr><td>11</td><td>S.K.Walichrad</td><td>Łowiczki</td><td>100zł</td></tr>
                    <tr><td>12</td><td>Władysława Zając</td><td>Łowiczki Jodłowa 10</td><td>100zł</td></tr>
                    <tr><td>13</td><td>H.S.Zemła</td><td>Łowiczki</td><td>200zł</td></tr>
                    <tr><td>14</td><td>M.L.Anteccy</td><td></td><td>100zł</td></tr>
                    <tr><td>15</td><td>Bernaś, Momot</td><td>Akacjowa 31</td><td>100zł</td></tr>
                    <tr><td>16</td><td>M.S.Boba</td><td>Andrychowska 352</td><td>200zł</td></tr>
                    <tr><td>17</td><td>Brusik</td><td>Andrychowska 280</td><td>100zł</td></tr>
                    <tr><td>18</td><td>L.S.Czopek</td><td>Andrychowska 262</td><td>100zł</td></tr>
                    <tr><td>19</td><td>Dziedzic</td><td>Lipowa</td><td>100zł</td></tr>
                    <tr><td>20</td><td>Frączek</td><td>Andrychowska 265</td><td>50zł</td></tr>
                    <tr><td>21</td><td>Frączek</td><td>Andrychowska 362</td><td>50zł</td></tr>
                    <tr><td>22</td><td>T.K.Frączek</td><td>Andrychowska 297</td><td>100zł</td></tr>
                    <tr><td>23</td><td>Krystyna Gagracz</td><td>Kasztanowa 130</td><td>50zł</td></tr>
                    <tr><td>24</td><td>Marcin Gagracz</td><td>Akacjowa 96</td><td>50zł</td></tr>
                    <tr><td>25</td><td>D.K.Głąb</td><td>Źródlana 1</td><td>50zł</td></tr>
                    <tr><td>26</td><td>Józef Głąb</td><td>Modrzewiowa</td><td>100zł</td></tr>
                    <tr><td>27</td><td>J.N.Głąb</td><td>Modrzewiowa</td><td>50zł</td></tr>
                    <tr><td>28</td><td>Górecki, Kobiałka</td><td></td><td>150zł</td></tr>
                    <tr><td>29</td><td>Hałat, Gryska</td><td>Andrychowska 305</td><td>100zł</td></tr>
                    <tr><td>30</td><td>Hałatek</td><td>Lipowa</td><td>100zł</td></tr>
                    <tr><td>31</td><td>Piotr Marcin Jurecki</td><td>Andrychowska 2</td><td>100zł</td></tr>
                    <tr><td>32</td><td>Knutel, Frączek</td><td>Modrzewiowa 3</td><td>100zł</td></tr>
                    <tr><td>33</td><td>Krzyścin</td><td>Pagórkowa 19</td><td>50zł</td></tr>
                    <tr><td>34</td><td>Józef Makuch</td><td>Akacjowa</td><td>50zł</td></tr>
                    <tr><td>35</td><td>Waldemar Makuch</td><td>Akacjowa</td><td>50zł</td></tr>
                    <tr><td>36</td><td>D.K.Oleksy</td><td>Pagórkowa 3</td><td>50zł</td></tr>
                    <tr><td>37</td><td>Jan Anna Płachta</td><td>Pagórkowa 10</td><td>100zł</td></tr>
                    <tr><td>38</td><td>S.W.Poręba</td><td></td><td>50zł</td></tr>
                    <tr><td>39</td><td>Rał</td><td>Andrychowska 27</td><td>100zł</td></tr>
                    <tr><td>40</td><td>I.S.</td><td></td><td>100zł</td></tr>
                    <tr><td>41</td><td>B.S.Szczęśniak</td><td>Lipowa 61a</td><td>100zł</td></tr>
                    <tr><td>42</td><td>Stanisława Kazimierz Ubik</td><td>Wrzosowa 2</td><td>100zł</td></tr>
                    <tr><td>43</td><td>Wolas</td><td>Wrzosowa 19</td><td>50zł</td></tr>
                    <tr><td>44</td><td>A.C.Zajac</td><td>Andrychowska 111</td><td>100zł</td></tr>
                    <tr><td>45</td><td></td><td>Akacjowa 24</td><td>100zł</td></tr>
                    <tr><td>46</td><td></td><td>Andrychowska 338</td><td>50zł</td></tr>
                    <tr><td>47</td><td></td><td>Kasztanowa 10</td><td>100zł</td></tr>
                    <tr><td>48</td><td></td><td>Kasztanowa 42</td><td>150zł</td></tr>
                    <tr><td>49</td><td>Anonimowo</td><td></td><td>100zł</td></tr>
                </table>
            </div>
            <Footer />
        </>
)}

export default Ofiarodawcy