import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Ogloszenia from './pages/Ogloszenia';
import Ofiarodawcy from './pages/Ofiarodawcy';
import Zapowiedzi from './pages/Zapowiedzi'
import Intencje from './pages/Intencje';
import IntencjePogrzebowe from './pages/IntencjePogrzebowe';
import IntecjeLowiczki from './pages/IntencjeLowicki'
import Roze from './pages/Roze'
import Ministranci from './pages/Ministranci';
import Galeria from './pages/Galeria';
import Kontakt from './pages/Kontakt';
import Poczta from './pages/poczta';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import RadaParafialna from './pages/RadaParafialna';
import Sakramenty from './pages/Sakramenty';
import SOM from './pages/SOM'

const page = document.getElementById('root')!;
const root = ReactDOM.createRoot(page);
root.render(
  <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<App />}/>
          <Route path='/ogloszenia' element={<Ogloszenia />}/>
          <Route path='/ofiarodawcy' element={<Ofiarodawcy />}/>
          <Route path='/zapowiedzi' element={<Zapowiedzi />}/>
          <Route path='/intencje' element={<Intencje />}/>
          <Route path='/intencjepogrzebowe' element={<IntencjePogrzebowe />}/>
          <Route path='/rozerozancowe' element={<Roze />}/>
          <Route path='/ministranci' element={<Ministranci />}/>
          <Route path='/intecjelowiczki' element={<IntecjeLowiczki />}/>
          <Route path='/galeria' element={<Galeria />}/>
          <Route path='/kontakt' element={<Kontakt />}/>
          <Route path='/poczta' element={<Poczta />}/>
          <Route path='/radaparafialna' element={<RadaParafialna />}/>
          <Route path='/sakramenty' element={<Sakramenty />}/>
          <Route path='/standardyochronymaloletnich' element={<SOM />}/>
        </Routes>
      </BrowserRouter>
  </React.StrictMode>
);