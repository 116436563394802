import Navigation from '../componets/Navigation'
import Footer from '../componets/Footer'

function IntencjeLowiczki() {
    return(
        <>
            <Navigation />
            <div className='article'>
                <div className='title'>Intecje Łowiczki</div>
                <ul>
                    <li>Piątek 6.12 O powołania do Służby Bożej, w int. Ojca Świętego i za cały Kościół (od Róży Różańcowej)</li>
                    <li>Niedziela 8.12 + Marek Wędrzyk</li>
                    <li>Niedziela 15.12 + Józef Zając</li>
                    <li>Piątek 20.12 ++ Stanisław Franciszka Zięba (od Jana Gliwy z rodz.)</li>
                    <li>Niedziela 22.12 + Lucyna Drożdż</li>
                    <li>Wtorek 24.12 (PASTERKA) ++ Piotr Rozalia Majkut, Antoni Franciszek s., Stanisława synowa,Andrzej wnuk </li>
                    <li>Środa 25.12++ Stanisław Maria Piotrowscy, Jan Anna, Rafał s., Jan Frączek, Rozalia Kobiałka</li>
                    <li>Czwartek 26.12 + Leszek Lewański (od żony)</li>
                    <li>Niedziela 29.12 + Mariusz Matla (od rodziców)</li>


                </ul>
            </div>
            <Footer />
        </>
    )
}

export default IntencjeLowiczki